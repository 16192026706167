(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .config(config);

    config.$inject = ['$httpProvider', '$sceProvider', '$breadcrumbProvider'];

    function config($httpProvider, $sceProvider, $breadcrumbProvider) {
        $sceProvider.enabled(false);
        $httpProvider.defaults.cache = false;
        $httpProvider.defaults.headers.get = {
            'Cache-Control' : 'no-cache, no-store, must-revalidate',
        	'Pragma' : 'no-cache',
        	'Expires' : 0,
        	'If-Modified-Since' : 0
        };

        $breadcrumbProvider.setOptions({
            templateUrl: 'app/layouts/navbar/breadcrumb.template.html'
        });
    }
})();
