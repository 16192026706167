(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('BidDeleteController', BidDeleteController);

    BidDeleteController.$inject = ['$uibModalInstance', 'entity', 'Bid', 'AlertService'];

    function BidDeleteController($uibModalInstance, entity, Bid, AlertService) {
        var vm = this;

        vm.bid = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            Bid.delete({ id: id, auction: vm.bid.auction }, function() {
                $uibModalInstance.close(true);
                AlertService.success("gspUserApp.bid.alert.deleteSuccess", { id: id });
            }, function(error) {
                AlertService.error(error.data.message);
            });
        }
    }
})();
