(function () {
    'use strict';

    angular
        .module('gspUserApp')
        .factory('Client', Client);

    Client.$inject = ['$resource'];

    function Client ($resource) {
        var resourceUrl =  'gspidentity/' + 'api/clients/:id'

        var service = $resource(resourceUrl, {}, {
            'get': {method: 'GET'},
            'query': {method: 'GET', isArray: true},
            'getAllowed': {
                method: 'GET',
                url: '/gspidentity/api/clients/member/:id/allowed',
                isArray: true
            }
        });

        return service;
    }
})();
