(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .factory('DateUtils', DateUtils);

    DateUtils.$inject = ['$filter'];

    function DateUtils ($filter) {

        var service = {
            convertDateTimeFromServer : convertDateTimeFromServer,
            convertLocalDateFromServer : convertLocalDateFromServer,
            convertLocalDateToServer : convertLocalDateToServer,
            convertLocalDateToCustom: convertLocalDateToCustom,
            createDateTimeFromServer: createDateTimeFromServer,
            dateformat : dateformat,
            removeTimeZone: removeTimeZone
        };

        return service;

        function removeTimeZone(date) {
            if (date) {
                return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
            }
        }

        function convertDateTimeFromServer (date) {
            if(date) {
                return new Date(moment(date).format('YYYY/MM/DD HH:mm'));
            }
        }

        function convertLocalDateFromServer (date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer (date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        function convertLocalDateToCustom(date, format) {
            if (date) {
                return $filter('date')(date, format);
            } else {
                return null;
            }
        }

        function createDateTimeFromServer(date) {
            if (date) {
                return new Date(moment(date).utc());
            }
        }

        function dateformat () {
            return 'yyyy-MM-dd';
        }
    }

})();
