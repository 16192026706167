(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('ProfileController', ProfileController);

    ProfileController.$inject = ['Principal'];

    function ProfileController (Principal) {
        var vm = this;

        var initPrincipal = function() {
            Principal.identity().then(function(account) {
                vm.account = account;
            });
        };

        initPrincipal();
    }
})();
