(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .factory('ConfigService', ConfigService);

    ConfigService.$inject = ['$q', '$http', 'ConfigValue'];

    function ConfigService($q, $http, ConfigValue) {

        var dataPromise = [];

        var service = {
            getConfiguration: getConfiguration
        };

        return service;

        function getConfiguration(_configPart) {
            if (angular.isUndefined(dataPromise[_configPart])) {
                dataPromise[_configPart] = $http.get('gspserver/api/configuration/' + _configPart).then(function(result) {
                    ConfigValue.socket = result.data;
                    return result.data;
                });
            }
            return dataPromise[_configPart];
        }
    }
})();
