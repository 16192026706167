(function() {
    'use strict';
    angular
        .module('gspUserApp')
        .directive('infiniteScroller', infiniteScroller);

    function infiniteScroller() {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs, ngModel) {
            var offset = parseInt(attrs.offset) || 40;
            var e = element[0];

            element.on('scroll', function() {
                if (e.scrollTop + e.offsetHeight >= e.scrollHeight - offset) {
                    scope.$apply(attrs.infiniteScroller);
                }
            });
        }
    }
})();
