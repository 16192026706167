(function() {
    'use strict';
    angular
        .module('gspUserApp')
        .factory('AuctionSettlement', AuctionSettlement);

    AuctionSettlement.$inject = ['$resource', 'DateUtils'];

    function AuctionSettlement ($resource, DateUtils) {

        return $resource(null, {}, {
            'get': {
                method: 'GET',
                url: 'gspserver/api/auction-settlements'
            }
        });
    }
})();
