(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('offer', {
                parent: 'entity',
                url: '/offer?page&sort&search&from&itemsPerPage',
                data: {
                    authorities: ['CAN_SEE_ALL_OFFERS']
                },
                ncyBreadcrumb: {
                    label: 'gspUserApp.offer.home.titleAll'
                },
                views: {
                    'content@logged': {
                        templateUrl: 'app/entities/offer/offers.html',
                        controller: 'OfferController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id, desc',
                        squash: true
                    },
                    search: null,
                    from: {
                        value: 'all',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            itemsPerPage: ($stateParams.itemsPerPage ? $stateParams.itemsPerPage : 0)
                        };
                    }],
                    cookiePagingParams: ['$cookies', function($cookies) {
                        return $cookies.getObject('offerParams');
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('offer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('paginator');
                        return $translate.refresh();
                    }]
                }
            })
            .state('offer-detail', {
                parent: 'offer',
                url: '/detail/{id}',
                data: {
                    authorities: ['CAN_VIEW_OFFER']
                },
                ncyBreadcrumb: {
                    label: 'Oferta {{vm.offer.id}}',
                    parent: 'offer'
                },
                views: {
                    'content@logged': {
                        templateUrl: 'app/entities/offer/offer-detail/offer-detail.html',
                        controller: 'OfferDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Offer', function($stateParams, Offer) {
                        return Offer.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        // TODO remove unnecessary translations
                        $translatePartialLoader.addPart('auction');
                        $translatePartialLoader.addPart('bid');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('offer');
                        return $translate.refresh();
                    }],
                    cookiePagingParams: ['$cookies', function($cookies) {
                        return $cookies.getObject('auctionToggle');
                    }]
                }
            })
            .state('my-submissions', {
                parent: 'entity',
                url: '/my-submissions?page&itemsPerPage',
                data: {
                    authorities: ['CAN_SEE_ALL_OFFERS']
                },
                ncyBreadcrumb: {
                    label: 'Moje wnioski'
                },
                views: {
                    'content@logged': {
                        templateUrl: 'app/entities/submission/submissions.html',
                        controller: 'SubmissionsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id, desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            itemsPerPage: ($stateParams.itemsPerPage ? $stateParams.itemsPerPage : 0)
                        };
                    }],
                    cookiePagingParams: ['$cookies', function($cookies) {
                        return $cookies.getObject('mySubmissions');
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('offer');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('paginator');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
