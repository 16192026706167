(function() {
    'use strict';
    angular
        .module('gspUserApp')
        .factory('Notification', Notification);

    Notification.$inject = ['$resource', 'DateUtils'];

    function Notification ($resource, DateUtils) {
        var resourceUrl =  'gspserver/' + 'api/notifications/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.date = DateUtils.convertDateTimeFromServer(data.date);
                    return angular.toJson(data);
                }
            },
            'getLatestImportant': {
                method: 'GET',
                url: 'gspserver/api/notifications/latest-important',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.date = DateUtils.convertDateTimeFromServer(data.date);
                    return angular.toJson(data);
                }
            },
            'queryHistory': {
                method: 'GET',
                isArray: true,
                url: 'gspserver/api/notifications/notifications-history'
            }
        });
    }
})();
