(function() {
    'use strict';
    angular
        .module('gspUserApp')
        .factory('Offer', Offer);

    Offer.$inject = ['$resource', 'DateUtils'];

    function Offer ($resource, DateUtils) {
        var resourceUrl =  'gspserver/' + 'api/offers/:operation/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.openingDate = DateUtils.createDateTimeFromServer(data.openingDate);
                        data.closingDate = DateUtils.createDateTimeFromServer(data.closingDate);
                        data.serviceStartDate = DateUtils.createDateTimeFromServer(data.serviceStartDate);
                        data.serviceFinishDate = DateUtils.createDateTimeFromServer(data.serviceFinishDate);
                        _.forEach(data.clusters, function (item) {
                            item.dateFrom = DateUtils.createDateTimeFromServer(item.dateFrom);
                            item.dateTo = DateUtils.createDateTimeFromServer(item.dateTo);
                        });
                        _.forEach(data.profiles, function (item) {
                            item.dateFrom = DateUtils.createDateTimeFromServer(item.dateFrom);
                            item.dateTo = DateUtils.createDateTimeFromServer(item.dateTo);
                        });
                    }
                    return data;
                }
            },
            'update': {
                method:'PUT',
                transformRequest: function (data) {
                    if (data) {
                        var copy = angular.copy(data);
                        copy.openingDate = DateUtils.removeTimeZone(copy.openingDate);
                        copy.closingDate = DateUtils.removeTimeZone(copy.closingDate);
                        copy.serviceStartDate = DateUtils.removeTimeZone(copy.serviceStartDate);
                        copy.serviceFinishDate = DateUtils.removeTimeZone(copy.serviceFinishDate);
                        _.forEach(copy.clusters, function (item) {
                            item.dateFrom = DateUtils.removeTimeZone(item.dateFrom);
                            item.dateTo = DateUtils.removeTimeZone(item.dateTo);
                        });
                        _.forEach(copy.profiles, function (item) {
                            item.dateFrom = DateUtils.removeTimeZone(item.dateFrom);
                            item.dateTo = DateUtils.removeTimeZone(item.dateTo);
                        });
                    }
                    return angular.toJson(copy);
                }
            },
            'save': {
                method:'POST',
                transformRequest: function (data) {
                    if (data) {
                        var copy = angular.copy(data);
                        copy.openingDate = DateUtils.removeTimeZone(copy.openingDate);
                        copy.closingDate = DateUtils.removeTimeZone(copy.closingDate);
                        copy.serviceStartDate = DateUtils.removeTimeZone(copy.serviceStartDate);
                        copy.serviceFinishDate = DateUtils.removeTimeZone(copy.serviceFinishDate);
                        _.forEach(copy.clusters, function (item) {
                            item.dateFrom = DateUtils.removeTimeZone(item.dateFrom);
                            item.dateTo = DateUtils.removeTimeZone(item.dateTo);
                        });
                        _.forEach(copy.profiles, function (item) {
                            item.dateFrom = DateUtils.removeTimeZone(item.dateFrom);
                            item.dateTo = DateUtils.removeTimeZone(item.dateTo);
                        });
                    }
                    return angular.toJson(copy);
                }
            },
            'delete': {
                method: 'PUT',
                url: 'gspserver/api/offers/delete'
            },
            'operation': {
                method: 'POST',
                url: 'gspserver/api/offers/offer-operation'
            },
            'clusterOperation': {
                method: 'POST',
                url: 'gspserver/api/offers/cluster-operation'
            },
            'getInitialBaseClusterForSubmission': {
                method: 'GET',
                url: 'gspserver/api/offers/getInitialBaseClusterForSubmission/:id'
            },
            'getAgreementTypesByClusterId': {
                isArray: true,
                method: 'GET',
                url: 'gspserver/api/offers/getAgreementTypesByClusterId/:clusterId'
            },
            'getPurposesByClusterId': {
                isArray: true,
                method: 'GET',
                url: 'gspserver/api/offers/getPurposesByClusterId/:clusterId'
            }
        });
    }
})();
