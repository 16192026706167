(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('login', {
            url: '/login',
            parent: 'visitor',
            data: {
                authorities: []
            },
            params: {
                redirectReason: null
            },
            templateUrl: 'app/login/login.html',
            controller: 'MainLoginController',
            controllerAs: 'vm',
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('login');
                    return $translate.refresh();
                }]
            }
        }).state('pl', {
            url: '/pl',
            parent:'visitor',
            data: {
                authorities: []
            },
            params: {
                redirectReason: null
            },
            templateUrl: 'app/login/login.html',
            controller: 'MainLoginController',
            controllerAs: 'vm',
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', 'tmhDynamicLocale', function($translate, $translatePartialLoader, tmhDynamicLocale) {
                    $translate.use('pl');
                    tmhDynamicLocale.set('pl');
                    setTimeout(function () {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('login');
                        $translate.refresh();
                    },100);
                    return $translate.refresh();
                }]
            }
        }).state('en', {
            url: '/en',
            parent:'visitor',
            data: {
                authorities: []
            },
            params: {
                redirectReason: null
            },
            templateUrl: 'app/login/login.html',
            controller: 'MainLoginController',
            controllerAs: 'vm',
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', 'tmhDynamicLocale', function($translate, $translatePartialLoader, tmhDynamicLocale) {
                    $translate.use('en');
                    tmhDynamicLocale.set('en');
                    setTimeout(function () {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('login');
                        $translate.refresh();
                    },100);
                    return $translate.refresh();
                }]
            }
        });
    }
})();
