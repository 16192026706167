(function() {
    'use strict';
    angular
        .module('gspUserApp')
        .factory('Report', Report);

    Report.$inject = ['$resource'];

    function Report ($resource, DateUtils) {
        var resourceUrl =  'gspserver/' + 'api/report';

        return $resource(resourceUrl, {}, {
            'getOrderLog': {
                method: 'GET',
                url: 'gspserver/api/order-log'
            },
            'getForAuction': {
                method: 'GET',
                url: 'gspserver/api/auctions/:auctionId/report'
            },
            'getForAuctionUsum': {
                method: 'GET',
                url: 'gspserver/api/auctions/:auctionId/usum'
            }
        });
    }
})();
