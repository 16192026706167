(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('PhonePasswordController', PhonePasswordController);

    PhonePasswordController.$inject = ['Auth', 'Principal', '$scope', 'Account', '$filter', '$uibModalInstance', '$timeout'];

    function PhonePasswordController(Auth, Principal, $scope, Account, $filter, $uibModalInstance, $timeout) {
        var vm = this;
        var passwordRegex = /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;
        vm.oldPassword = null;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('OK');
        }

        var initPrincipal = function() {
            Principal.identity().then(function(account) {
                vm.account = account;
            });
        };
        initPrincipal();

        function changePassword() {
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                vm.doNotMatch = null;
                Auth.changePhonePassword(vm.oldPassword, vm.password).then(function() {
                    vm.error = null;
                    vm.success = 'OK';
                    vm.oldPassword = vm.password = vm.confirmPassword = null;
                    $scope.form.$setPristine();
                    vm.account = Account.get();
                    $timeout(function() {
                        $uibModalInstance.dismiss('OK');
                    }, 700);
                }).catch(function(result) {
                     vm.success = null;
                     if (result.data == 'Incorrect old password')
                         vm.oldPasswordIncorrect = 'ERROR';
                     else
                         vm.error = 'ERROR';
                });
            }
        }

		  vm.checkPasswordStrength = function() {
				return vm.password && !passwordRegex.test(vm.password);
		  };
    }
})();
