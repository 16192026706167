(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .config(['dynamicNumberStrategyProvider', function(dynamicNumberStrategyProvider){
            dynamicNumberStrategyProvider.addStrategy('allowances', {
                numInt: 15,
                numFract: 0,
                numNeg: false,
                numThousand: true,
                numThousandSep: ' '
            });
            dynamicNumberStrategyProvider.addStrategy('bundle', {
                numInt: 9,
                numFract: 0,
                numNeg: false,
                numThousand: true,
                numThousandSep: ' '
            });
            dynamicNumberStrategyProvider.addStrategy('price', {
                numInt: 8,
                numFract: 2,
                numFixed: true,
                numSep: ',',
                numNeg: false,
                numThousand: true,
                numThousandSep: ' '
            });
            dynamicNumberStrategyProvider.addStrategy('parameters', {
                numInt: 8,
                numFract: 3,
                numFixed: false,
                numSep: ',',
                numNeg: false,
                numThousand: true,
                numThousandSep: ' '
            });
            dynamicNumberStrategyProvider.addStrategy('parametersInput', {
                numInt: 7,
                numFract: 7,
                numFixed: false,
                numSep: ',',
                numNeg: false,
                numThousand: true,
                numThousandSep: ' '
            });
            dynamicNumberStrategyProvider.addStrategy('exchangeRate', {
                numInt: 1,
                numFract: 4,
                numFixed: true,
                numSep: ',',
                numNeg: false
            });
            dynamicNumberStrategyProvider.addStrategy('variation', {
                numInt: 2,
                numFract: 2,
                numFixed: true,
                numSep: ',',
                numNeg: false,
                numAppend: "%"
            });
            dynamicNumberStrategyProvider.addStrategy('normal', {
                numInt: 3,
                numFract: 3,
                numFixed: true,
                numSep: ',',
                numNeg: false
            });
            dynamicNumberStrategyProvider.addStrategy('percent', {
                numInt: 3,
                numFract: 2,
                numSep: ',',
                numNeg: false
            });
        }]);
})();
