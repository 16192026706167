(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('auction', {
                parent: 'entity',
                deepStateRedirect: true,
                url: '/auction?page&sort&search&from&itemsPerPage',
                data: {
                    authorities: ['LOGIN_USER']
                },
                ncyBreadcrumb: {
                    label: 'gspUserApp.auction.home.titleAll'
                },
                views: {
                    'content@logged': {
                        templateUrl: 'app/entities/auction/auctions.html',
                        controller: 'AuctionController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id, desc',
                        squash: true
                    },
                    search: null,
                    from: {
                        value: 'all',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            itemsPerPage: ($stateParams.itemsPerPage ? $stateParams.itemsPerPage : 0)
                        };
                    }],
                    cookiePagingParams: ['$cookies', function($cookies) {
                        return $cookies.getObject('auctionParams');
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('auction');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('paginator');
                        return $translate.refresh();
                    }]
                }
            })
            .state('auction-detail', {
                parent: 'auction',
                url: '/auction/{id}',
                data: {
                    authorities: ['LOGIN_USER']
                },
                ncyBreadcrumb: {
                    label: 'Aukcja {{vm.auction.id}}',
                    parent: 'auction'
                },
                views: {
                    'content@logged': {
                        templateUrl: 'app/entities/auction/auction-detail.html',
                        controller: 'AuctionDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('auction');
                        $translatePartialLoader.addPart('bid');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Auction', function($stateParams, Auction) {
                        return Auction.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    cookieToggleParams: ['$cookies', function($cookies) {
                        return $cookies.getObject('auctionToggle');
                    }],
                }
            })
            .state('bid-detail', {
                parent: 'entity',
                url: '/bid/{id}',
                data: {
                    authorities: ['LOGIN_USER']
                },
                views: {
                    'content@logged': {
                        templateUrl: 'app/entities/bid/bid-detail.html',
                        controller: 'BidDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bid');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Bid', function($stateParams, Bid) {
                        return Bid.get({
                            id: $stateParams.id
                        }).$promise;
                    }]
                }
            })
            .state('bid-settlement-detail', {
                parent: 'entity',
                url: '/bid-settlement/{id}',
                data: {
                    authorities: ['LOGIN_USER']
                },
                views: {
                    'content@logged': {
                        templateUrl: 'app/entities/bid/bid-settlement-detail.html',
                        controller: 'BidSettlementDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bid');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'BidSettlement', function($stateParams, BidSettlement) {
                        return BidSettlement.get({
                            id: $stateParams.id
                        }).$promise;
                    }]
                }
            });
    }

})();
