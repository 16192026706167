(function () {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('SubmissionDetailController', SubmissionDetailController);

    SubmissionDetailController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'entity', 'offer', 'clusterRoot',
     'Submission', 'Auction', 'Documentation', 'AlertService', '$confirm', '$filter', '$http', '$translate'];

    function SubmissionDetailController($scope, $uibModalInstance, $uibModal, entity, offer, clusterRoot, Submission, Auction,
                                        Documentation, AlertService, $confirm, $filter, $http, ngTranslate) {
        var vm = this;
        vm.submission = entity;
        vm.offer = offer;
        vm.clear = clear;
        vm.makeOperation = makeOperation;
        vm.editSubmission = editSubmission;
        vm.addDocument = addDocument;
        vm.downloadFile = downloadFile;
        vm.openSubmissionHistory = openSubmissionHistory;
        vm.deleteDocumentationFile = deleteDocumentationFile;
        vm.generateSSAForm = generateSSAForm;
        vm.allocationDecisionModal = allocationDecisionModal;
        vm.confirmAllocationDecisionModal = confirmAllocationDecisionModal;
        var $translate = $filter('translate');

        var findWarehouse = function(_id){
            Auction.getWarehouseInstallations().$promise.then(function(resp){
                var warehouse = _.find(resp, {id: parseInt(_id)});
                vm.offer.warehouseInstallation = warehouse.name || '';
                vm.offer.eicCode = warehouse.codeEIC;
            });
        };
        findWarehouse(vm.offer.warehouseInstallationId);

        function getUsumType() {
            var currentCluster;
            if (clusterRoot) {
                currentCluster = _.find(vm.offer.clusters, {
                    id: vm.submission.clusterId
                });
                vm.submission.usumType = currentCluster.usumType;
            } else {
                _.each(vm.offer.clusters, function (cluster) {
                    currentCluster = _.find(cluster.subClusters, {
                        id: vm.submission.clusterId
                    });
                    if (currentCluster) {
                        vm.submission.usumType = currentCluster.usumType;
                    }
                });
            }
        }
        getUsumType();

        function getDocumentations() {
            if (vm.submission.id) {
                Documentation.getDocumentationsBySubmissionId({ submissionId: vm.submission.id }).$promise.then(function(resp){
                    _.each(resp, function (item) {
                        item.name = decodeURI(item.name);
                    });
                    vm.documentation = resp;
                });
            }
        }
        getDocumentations();


        function editSubmission(submissionId) {
            $uibModal.open({
                templateUrl: 'app/entities/submission/submission-dialog.html',
                controller: 'SubmissionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Submission', function (Submission) {
                        return Submission.getSubmissionById({
                            id: submissionId
                        }).$promise;
                    }],
                    offer: [function () {
                        return vm.offer;
                    }],
                    baseCluster: ['Offer', function (Offer) {
                        return Offer.getInitialBaseClusterForSubmission({
                            id: vm.offer.id
                        }).$promise;
                    }]
                }
            }).result.then(function () {
                // $state.go('offer-detail', {
                //     id: vm.offer.id
                // }, {
                //     reload: true
                // });
            }, function () {
                // $state.go('offer-detail', {
                //     id: vm.offer.id
                // }, {
                //     reload: false
                // });
            });
        }


        function makeOperation(operationType) {
            var text = '';
            var title = '';
            //TODO WYWALI NIEPOTRZEBNE
            switch (operationType) {
            case 'SUBMIT':
                text = 'gspUserApp.offer.submitSubmissionDialog.question';
                title = 'gspUserApp.offer.submitSubmissionDialog.title';
                break;
            case 'ACCEPT':
                text = 'gspUserApp.offer.acceptSubmissionDialog.question';
                title = 'gspUserApp.offer.acceptSubmissionDialog.title';
                break;
            case 'TOIMPROVE':
                text = 'gspUserApp.offer.toImproveSubmissionDialog.question';
                title = 'gspUserApp.offer.toImproveSubmissionDialog.title';
                break;
            case 'IMPROVE':
                text = 'gspUserApp.offer.improveSubmissionDialog.question';
                title = 'gspUserApp.offer.improveSubmissionDialog.title';
                break;
            case 'WITHDREW':
                text = 'gspUserApp.offer.withdrawnSubmissionDialog.question';
                title = 'gspUserApp.offer.withdrawnSubmissionDialog.title';
            }

            $confirm({
                text: $translate(text, {
                    id: vm.submission.id
                }),
                title: $translate(title),
                ok: $translate('gspUserApp.offer.action.yes'),
                cancel: $translate('gspUserApp.offer.action.no')
            }).then(function () {
                var operation = {
                    "operation": operationType,
                    "id": vm.submission.id
                };

                Submission.operation(operation, function (data) {
                    vm.submission = data;
                    getUsumType();
                    getDocumentations();
                    $uibModalInstance.close();
                }, function (error) {
                    AlertService.error(error.data.message);
                });
            });
        }

         function openSubmissionHistory(sid) {
            $uibModal.open({
                templateUrl: 'app/entities/submission/submission-history/submission-history.html',
                controller: 'SubmissionHistoryController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: ['$stateParams', 'Submission', function($stateParams, Submission) {
                        return Submission.getSubmissionById({
                            id: sid
                        }).$promise;
                    }],
                    decision: ['$stateParams', 'Submission', function($stateParams, Submission) {
                        return Submission.getAllocationDecisionHistoryBySubmissionId({
                            id: vm.submission.id
                        }).$promise;
                    }]
                }
            });
         }

        function addDocument() {
            $uibModal.open({
                templateUrl: 'app/entities/documentation/documentation-dialog/documentation-dialog.html',
                controller: 'DocumentationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['$stateParams', 'Submission', function($stateParams, Submission) {
                        return {
                            documentStatus: 'SUBMITTED',
                            submissionId: vm.submission.id,
                            memberId: vm.submission.memberId,
                            memberCode: vm.submission.memberCode
                        };
                    }],
                    fromSubmission: true,
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('documentation');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function () {
                getDocumentations();
            }, function () {
            });
        }

        function downloadFile(id, name) {
            $http({
                method: 'GET',
                url: 'gspserver/api/documentation/getDocumentationFile/' + id,
                responseType: 'arraybuffer'
            }).then(function(data) {
                var blob = new Blob([data.data]);
                saveAs(blob, name);
            });
        }

        function deleteDocumentationFile(doc) {
            $confirm({
                text: $translate('gspUserApp.documentation.deleteDocumentDialog.question') + ': ' + $translate('gspUserApp.documentation.documentNames.' + doc.documentName) + ' - ' + doc.name,
                title: $translate('gspUserApp.documentation.deleteDocumentDialog.title'),
                ok: $translate('gspUserApp.offer.action.yes'),
                cancel: $translate('gspUserApp.offer.action.no')
            }).then(function () {
                Documentation.deleteDocumentationFile({ id: doc.id }, doc, function () {
                    getDocumentations();
                    AlertService.success("gspUserApp.documentation.alert.deleteFileSuccess", { id: doc.id });
                }, function (error) {
                    AlertService.error(error.data.message);
                });
            });
        }

        function generateSSAForm(submissionId) {
            Submission.generateSSAForm({
                "submissionId": submissionId,
                "type": 'SSA_FORM',
                "format": 'XLS',
                "langKey": ngTranslate.storage().get('NG_TRANSLATE_LANG_KEY')
            }, function (data, headers) {
                var blob = b64toBlob(data.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
                saveAs(blob, data.fileName);
            }, function (error) {
                AlertService.error(error.data.message);
            });
        }

        function b64toBlob(b64Data, contentType, sliceSize) {
            contentType = contentType || '';
            sliceSize = sliceSize || 512;

            var byteCharacters = atob(b64Data);
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, {
                type: contentType
            });
            return blob;
        }

        function allocationDecisionModal() {
            $uibModal.open({
                templateUrl: 'app/entities/submission/allocation-decision/allocation-decision.html',
                controller: 'AllocationDecisionController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: {
                        decisionDescription: null,
                        decisionStatus: null,
                        decisionConfirm: false
                    },
                    submission: vm.submission
                }
            }).result.then(function (data) {
                vm.submission.allocationDecision = data;
            }, function () { });
        }

        function confirmAllocationDecisionModal() {
            $confirm({
                text: $translate('gspUserApp.offer.sendDecisionDialog.question'),
                title: $translate('gspUserApp.offer.sendDecisionDialog.title'),
                ok: $translate('gspUserApp.offer.action.yes'),
                cancel: $translate('gspUserApp.offer.action.no')
            }).then(function () {
                Submission.confirmAllocationDecision({ 'submissionId': vm.submission.id }, {}, function () {
                    vm.submission.allocationDecision.decisionConfirm = true;
                    AlertService.success("gspUserApp.offer.alert.sendDecisionSuccess", { id:  vm.submission.id });
                }, function (error) {
                    AlertService.error(error.data.message);
                });
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
