(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('NotificationController', NotificationController);

    NotificationController.$inject = ['$scope', '$rootScope', '$state', 'Notification', 'ParseLinks', 'AlertService', '$sce', 'DateUtils', '$uibModal'];

    function NotificationController($scope, $rootScope, $state, Notification, ParseLinks, AlertService, $sce, DateUtils, $uibModal) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = 'date';
        vm.reverse = false;
        vm.transition = transition;
        vm.latestImportantDate = null;
        vm.notifications = [];

        loadAll();

        function loadAll() {
            Notification.query({
                page: 0,
                size: 100,
                sort: sort()
            }, onSuccess);

            Notification.getLatestImportant({}, function(result) {
                vm.latestImportantDate = DateUtils.removeTimeZone(result.date);
            });

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.notifications = data;
                for (var i in vm.notifications) {
                    if (vm.notifications[i].params != null) {
                        vm.notifications[i].params = angular.fromJson(vm.notifications[i].params);
                    }
                }
            }
        }

        vm.getAsHtml = function(content) {
            if(content) {
                var result = breakLine(content);
                return $sce.trustAsHtml("<div>" + result + "</div>");
            }
            return content;
        }

        function breakLine(content) {
            var split = content.split("\n");
            var result = "";
            for(var i in split) {
                result += split[i] + "<br/>";
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        vm.refresh = function() {
            $state.reload();
        };

        vm.notificationHistory = function () {
            $uibModal.open({
                templateUrl: 'app/entities/notification/notification-history/notification-history.html',
                controller: 'NotificationHistoryController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Notification', function (Notification) {
                        return vm.notifications;
                    }]
                }
            }).result.then(function () {
            }, null);
        };

        var overrideLatestImportantDate = function(notification) {
            var importantUntil = new Date(moment(notification.importantUntil).format('YYYY/MM/DD HH:mm'));
            if(notification.importantUntil != null && importantUntil > new Date()) {
                vm.latestImportantDate = notification.date;
            }
        };

        $rootScope.$on('NEW_NOTIFICATION', function(event, args) {
            if(args && args.notification) {
                for(var i in vm.notifications) {
                    if(vm.notifications[i].id == args.notification.id) {
                        return;
                    }
                }
                var notification = args.notification;
                overrideLatestImportantDate(notification);
                if (notification.params != null) {
                    notification.params = angular.fromJson(notification.params);
                }
                vm.notifications.unshift(notification);
            } else {
                loadAll();
            }
        });

        vm.canShowLink = function(notification) {
            return notification.auctionId != null || notification.offerId != null;
        };

        vm.showOfferLink = function (notification) {
            return notification.offerId !== null;
        };

        vm.showAuctionLink = function (notification) {
            return notification.auctionId !== null;
        };
    }
})();
