(function() {
    'use strict';
    angular
        .module('gspUserApp')
        .factory('Navbar', Navbar);

    Navbar.$inject = ['$resource', 'DateUtils'];

    function Navbar ($resource, DateUtils) {
        var resourceUrl =  'gspserver/' + 'api/events';

        return $resource(resourceUrl, {}, {
            'getServerDate': {
                method: 'GET',
                url: 'gspserver/api/configuration/now'
            }
        });
    }
})();
