(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('DocsController', DocsController);

    DocsController.$inject = ['$uibModalInstance', 'JhiLanguageService'];

    function DocsController($uibModalInstance, JhiLanguageService) {
        var vm = this;

        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('OK');
        }

        JhiLanguageService.getCurrent().then(function(lang){
            vm.currentLanguage = lang;
        });

    }
})();
