(function () {
    'use strict';

    angular
        .module('gspUserApp')
        .factory('Insurance', Insurance);

    Insurance.$inject = ['$resource'];

    function Insurance ($resource) {
        var resourceUrl =  'gspidentity/' + 'api/insurances/:id'

        var service = $resource(resourceUrl, {}, {
            'get': {method: 'GET'},
            'query': {method: 'GET', isArray: true},
            'update': {method: 'PUT'},
            'save': {method: 'POST'},
            'getForMember': {
                method: 'GET',
                isArray: true,
                url: 'gspidentity/api/members/:id/insurances'
            },
            'getForMemberAndAuction': {
                method: 'GET',
                url: 'gspserver/api/collateral/member'
            },
            'getForClientAndAuction': {
                method: 'GET',
                url: 'gspserver/api/collateral/client'
            }
        });

        return service;
    }
})();
