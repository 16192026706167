(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('RequestResetController', RequestResetController);

    RequestResetController.$inject = ['$timeout', 'Auth'];

    function RequestResetController ($timeout, Auth) {
        var vm = this;

        vm.requestReset = requestReset;
        vm.resetAccount = {};
        vm.success = null;
        vm.token = null;

        $timeout(function (){angular.element('#login').focus();}, 500);

        function requestReset () {
            vm.invalidCaptcha = false;
            vm.resetAccount.captchaToken = vm.token;
            Auth.resetPasswordInit(vm.resetAccount).then(function () {
                vm.success = 'OK';
            }, function(error) {
                if(error.data == "error.captcha.invalid") {
                    vm.invalidCaptcha = true;
                } else {
                    vm.success = 'OK';
                }
            });
        }

        vm.generateToken = function() {
            var token = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for( var i=0; i < 64; i++ ) {
                token += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            vm.token = token;
        }
        vm.generateToken();

    }
})();
