(function () {
    'use strict';
    angular
        .module('gspUserApp')
        .directive('dropdownMenu', dropdownMenu)
        .directive('dropdownMenuContainer', dropdownMenuContainer);

    function dropdownMenu() {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element) {
            element.click(toggle);
            function toggle() {
                $(this).parent().find('ul').toggle("fast", function () {});
            }
        }
    }

    function dropdownMenuContainer() {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element) {
            element.mouseleave(function() {
                element.find('ul ul').hide("fast", function () {});
            });
            element.mouseenter(function() {
                element.find('li.active ul').show("fast", function () {});
            });

        }
    }
})();
