(function () {
    'use strict';

    angular
        .module('gspUserApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', '$urlRouterProvider'];

    function stateConfig($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise('/auction');
        $stateProvider.state('app', {
            abstract: true,
            template: '<div ui-view><div>',
            ncyBreadcrumb: {
                skip: true
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('notification');
                    $translatePartialLoader.addPart('error');
                }]
            }
        }).state('visitor', {
            parent: 'app',
            ncyBreadcrumb: {
                skip: true
            },
            abstract: true,
            views: {
                '': {
                    template: '<div ui-view></div>'
                },

            },

        }).state('logged', {
            parent: 'app',
            abstract: true,
            views: {
                '': {
                    template: '<div ui-view="navbar" ng-cloak></div>' + '<div ui-view="menu" class="left-menu" dropdown-menu-container></div>' + ' <div ui-view="sidebar" class="left-sidebar"></div>' + '<div class="container-fluid page-body">' +
                        '<div ui-view="content"></div></div'
                },
                'navbar@logged': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                },
                'menu@logged': {
                    templateUrl: 'app/layouts/menu/menu.html',
                    controller: 'MenuController',
                    controllerAs: 'vm'
                },
                'sidebar@logged': {
                    templateUrl: 'app/layouts/sidebar/sidebar.html',
                    controller: 'SidebarController',
                    controllerAs: 'vm'
                },


            },

        }).state('reset', {
            parent: 'app',
            abstract: true,
            views: {
                '': {
                    template: '<div ui-view></div>'
                },

            },
        });
    }
})();
