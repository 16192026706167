(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('HelpController', HelpController);

    HelpController.$inject = ['$scope', '$uibModalInstance', '$translate'];

    function HelpController($scope, $uibModalInstance, $translate) {
        var vm = this;
        vm.clear = clear;
        vm.currentLang = $translate.use();

        function clear() {
            $uibModalInstance.dismiss('OK');
        }

    }
})();
