(function() {
    'use strict';

    angular
        .module('gspUserApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'angular-confirm',
            'dynamicNumber',
            'ngAnimate',
            'underscore',
            'angular-jwt',
            'ncy-angular-breadcrumb'
        ])
        .run(run);

    run.$inject = ['$rootScope', '$location', '$state', 'stateHandler', 'translationHandler', '$confirmModalDefaults', 'NotificationSocket', 'Account', '$window', 'Auth'];

    function run($rootScope, $location, $state, stateHandler, translationHandler, $confirmModalDefaults, NotificationSocket, Account, $window, Auth) {
        $window.onbeforeunload = function() {
            Auth.logout();
        };
        $confirmModalDefaults.templateUrl = 'app/layouts/confirm/confirm.template.html';
        stateHandler.initialize();
        translationHandler.initialize();

        $rootScope.$on('$locationChangeSuccess', function() {
            $rootScope.actualLocation = $location.absUrl();
        });

        $rootScope.$watch(function() {
            return $location.absUrl()
        }, function(newLocation, oldLocation) {
            if ($rootScope.actualLocation === newLocation) {
                $rootScope.$broadcast('backEvent');
            }
        });

        Account.get().$promise.then(function() {
            NotificationSocket.getConnection();
        });
    }
})();
