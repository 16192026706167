(function () {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('NotificationHistoryController', NotificationHistoryController);

    NotificationHistoryController.$inject = ['$timeout', '$scope', '$uibModalInstance', '$rootScope', '$state', 'entity', 'Notification', 'ParseLinks',
    'AlertService', '$uibModal', '$sce', '$confirm', '$filter', 'DateUtils'];

    function NotificationHistoryController($timeout, $scope, $uibModalInstance, $rootScope, $state, entity, Notification, ParseLinks, AlertService, $uibModal,
    $sce, $confirm, $filter, DateUtils) {
        var vm = this;
        vm.clear = clear;
        vm.notificationHistory = entity;
        vm.events = [];

        var limit = 15;
        var queryParams = {
            page: 0,
            size: limit
        };

        vm.isFiltered = false;
        vm.queryParamsExt = {
            "dateFrom": null,
            "dateTo": null,
            "action": null,
            page: 0,
            size: limit
        };

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
        vm.loadMore = loadMore;

        vm.selectizeConfig = {
            create: false,
            valueField: 'id',
            labelField: 'name',
            maxItems: 1,
            allowEmptyOption: true
        };

        vm.actions = [{
            id: 'AUCTION_CREATED',
            name: 'gspUserApp.notification.auctionCreated'
        }, {
            id: 'AUCTION_PUBLISHED',
            name: 'gspUserApp.notification.auctionPublished'
        }, {
            id: 'AUCTION_STARTED',
            name: 'gspUserApp.notification.auctionStarted'
        }, {
            id: 'AUCTION_FINISHED',
            name: 'gspUserApp.notification.auctionFinished'
        }, {
            id: 'AUCTION_SETTLEMENT',
            name: 'gspUserApp.notification.auctionSetlement'
        }, {
            id: 'AUCTION_INVALIDATED',
            name: 'gspUserApp.notification.auctionInvalidated'
        }, {
            id: 'AUCTION_DELETED',
            name: 'gspUserApp.notification.auctionDeleted'
        }, {
            id: 'OFFER_CREATED',
            name: 'gspUserApp.notification.offerCreated'
        }, {
            id: 'OFFER_PUBLISHED',
            name: 'gspUserApp.notification.offerPublished'
        }, {
            id: 'OFFER_STARTED',
            name: 'gspUserApp.notification.offerStarted'
        }, {
            id: 'OFFER_FINISHED',
            name: 'gspUserApp.notification.offerFinished'
        }, {
            id: 'OFFER_SETTLEMENT',
            name: 'gspUserApp.notification.offerSettlement'
        }, {
            id: 'OFFER_INVALIDATED',
            name: 'gspUserApp.notification.offerInvalidated'
        }, {
            id: 'OFFER_DELETED',
            name: 'gspUserApp.notification.offerDeleted'
        }, {
            id: 'CUSTOM_MANUAL',
            name: 'gspUserApp.notification.customManual'
        }, {
            id: 'COMPLETING_APPLICATION',
            name: 'gspUserApp.notification.completingApplication'
        }, {
            id: 'REJECTED_APPLICATION',
            name: 'gspUserApp.notification.rejectedApplication'
        }, {
            id: 'INITIAL_ALLOCATION',
            name: 'gspUserApp.notification.initialAllocation'
        }, {
            id: 'SETTLEMENT_APPLICATION',
            name: 'gspUserApp.notification.settlementApplication'
        }, {
            id: 'ADDITION_SSA_PROJECT',
            name: 'gspUserApp.notification.additionSSAProject'
        }, {
            id: 'ADDING_BILATERALLY_SIGNED_SSA',
            name: 'gspUserApp.notification.addingBilaterallySignedSSA'
        }, {
            id: 'ADDITION_OSM_MESSAGE',
            name: 'gspUserApp.notification.additionOSMMessage'
        }];
                
        loadAll();

        function loadAll(_newEvent) {
            if (vm.queryParamsExt.fromDate) {
                vm.queryParamsExt.fromDate = DateUtils.removeTimeZone(vm.queryParamsExt.fromDate);
            }
            if (vm.queryParamsExt.toDate) {
                vm.queryParamsExt.toDate = DateUtils.removeTimeZone(vm.queryParamsExt.toDate);
            }

            var lastPage = null;
            if (vm.isFiltered) {
                lastPage = vm.queryParamsExt.page;
                vm.queryParamsExt.page = (_newEvent) ? 0 : lastPage;
                Notification.queryHistory(vm.queryParamsExt, onSuccess, onError);
            } else {
                lastPage = queryParams.page;
                queryParams.page = (_newEvent) ? 0 : lastPage;
                Notification.queryHistory(queryParams, onSuccess, onError);
            }


            function onSuccess(data, headers) {
                if (vm.queryParamsExt.fromDate) {
                    vm.queryParamsExt.fromDate = DateUtils.convertDateTimeFromServer(vm.queryParamsExt.fromDate);
                }
                if (vm.queryParamsExt.toDate) {
                    vm.queryParamsExt.toDate = DateUtils.convertDateTimeFromServer(vm.queryParamsExt.toDate);
                }

                if (data.length >= limit) {
                    if (vm.isFiltered) {
                        vm.queryParamsExt.page = lastPage + 1;
                    } else {
                        queryParams.page = lastPage + 1;
                    }
                }

                for (var i = 0; i < data.length; i++) {
                    if (!_.where(vm.events, data[i]).length) {
                        if (_newEvent) {
                            data[i].new = 'success';
                            vm.events.unshift(data[i]);
                        } else {
                            vm.events.push(data[i]);
                        }
                    }
                }
                vm.loaderActivity = 'disactive';
            }

            $timeout(function () {
                angular.element('.form-group:eq(1)>input').focus();
            }, 500);

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loaderActivity = 'disactive';
            }
        }

        function loadMore() {
            loadAll();
            vm.loaderActivity = 'active';
        }

        vm.isFromDateBeforeToDate = function () {
            var returned = true;
            returned = vm.queryParamsExt.action ? false : false;
            if (vm.queryParamsExt.fromDate === null && vm.queryParamsExt.toDate === null && returned !== false) {
                returned = true;
            }
            if (vm.queryParamsExt && vm.queryParamsExt.fromDate && vm.queryParamsExt.toDate) {
                if (!moment(vm.queryParamsExt.fromDate).isBefore(vm.queryParamsExt.toDate)) {
                    return true;
                } else {
                    returned = false;
                }
            }
            return returned;
        };

        vm.datePickerOpenStatus = {
            fromDate: false,
            toDate: false
        };

        vm.openCalendar = function (date) {
            vm.datePickerOpenStatus[date] = true;
        };

        vm.filter = function () {
            vm.isFiltered = true;
            vm.queryParamsExt.page = 0;
            vm.queryParamsExt.action = vm.action;
            vm.events = [];
            loadAll();
        };

        vm.clearFilter = function () {
            queryParams.page = 0;
            queryParams.size = limit;
            vm.isFiltered = false;

            vm.queryParamsExt.fromDate = null;
            vm.queryParamsExt.toDate = null;
            vm.action = null;
            delete vm.queryParamsExt.action;
            vm.queryParamsExt.page = 0;
            vm.queryParamsExt.size = limit;

            vm.events = [];
            loadAll();
        };
    }
})();
