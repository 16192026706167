(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .directive('safeString', numericOnly);

    function numericOnly() {
        var directive = {
            require: 'ngModel',
            link: linkFunc,
        };

        return directive;

        function linkFunc(scope, element, attrs, modelCtrl) {
            modelCtrl.$parsers.push(function (inputValue) {
                var transformedInput = inputValue ? inputValue.replace(/[^\wąćęłńóśżźĄĆĘŁŃÓŚŻŹ\ !#$%\^&*()+,\-.:;=?@\[\]\{\}\|]/g,'') : null;

                if (transformedInput!=inputValue) {
                    modelCtrl.$setViewValue(transformedInput);
                    modelCtrl.$render();
                }

                return transformedInput;
            });
        }
    }
})();
