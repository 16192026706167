(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('MainLoginController', MainLoginController);

    MainLoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', 'ConfigService', '$uibModalStack', '$stateParams', '$cookies', '$uibModal', '$translate'];

    function MainLoginController($rootScope, $state, $timeout, Auth, ConfigService, $uibModalStack, $stateParams, $cookies, $uibModal, $translate) {
        var vm = this;
        var cookieLawAccepted = $cookies.get('cookieLawAccepted');

        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.clear = clear;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.requestResetPassword = requestResetPassword;
        vm.redirectReason = $stateParams.redirectReason;
        vm.username = null;
        vm.acceptCookie = acceptCookie;
        vm.cookieAccepted = cookieLawAccepted;
        vm.curentLang = $translate.use();
        vm.token = null;
        vm.captchaResponse = null;
        vm.userNotActive = false;
        vm.memberNotActive = false;

        $timeout(function() {
            angular.element('#username').focus();
        }, 500);

        function acceptCookie(_param) {
            if (!cookieLawAccepted) {
                $cookies.put('cookieLawAccepted', true);
                vm.cookieAccepted = true;
            }
        }

        function clear() {
            $uibModalStack.dismissAll();
        }

        vm.openCookieInfoModal = function() {
            $uibModal.open({
                templateUrl: 'app/login/cookie-info.html',
                controller: 'MainLoginController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowClass: 'large-modal'
            });
        };

        function cancel() {
            vm.credentials = {
                username: null,
                password: null
            };
            vm.authenticationError = false;
        }

        function login(event) {
            vm.redirectReason = null;
            event.preventDefault();
            vm.invalidCaptcha = false;
            vm.authenticationError = false;
            vm.userNotActive = false;
            vm.memberNotActive = false;
            vm.captchaToken = vm.token
            Auth.login({
                username: vm.username,
                password: vm.password,
                captchaResponse: vm.captchaResponse,
                captchaToken: vm.captchaToken
            }).then(function() {
                vm.authenticationError = false;
                $state.go('auction');
                $rootScope.$broadcast('authenticationSuccess');
                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }, function(error) {
                if(error.data.message && error.data.message == "error.login.reachedNumberOfAttemps") {
                    generateToken();
                } else if(error.data.message && error.data.message == "error.captcha.invalid") {
                    vm.invalidCaptcha = true;
                    generateToken();
                } else if(error.data.message && error.data.message == "error.login.userNotActive"){
                    vm.userNotActive = true;
                } else if(error.data.message && error.data.message == "error.login.memberNotActive"){
                    vm.memberNotActive = true;
                }
                vm.authenticationError = true;
            });
        }

        function register() {
            $state.go('register');
        }

        function requestResetPassword() {
            $state.go('requestReset');
        };

        var generateToken = function() {
            var token = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for( var i=0; i < 64; i++ ) {
                token += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            vm.token = token;
        }

        vm.showDocs = function() {
            $uibModal.open({
                templateUrl: 'app/docs/docs.html',
                controller: 'DocsController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }]
                }
            });
        };
    }
})();
