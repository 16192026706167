(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('DocumentationController', DocumentationController);

    DocumentationController.$inject = ['$scope', '$rootScope', '$state', 'Documentation', 'ParseLinks', 'AlertService',
        'pagingParams', 'paginationConstants', '$uibModal', '$confirm', '$filter', '$cookies', '$http', '$translate'];

    function DocumentationController($scope, $rootScope, $state, Documentation, ParseLinks, AlertService,
        pagingParams, paginationConstants, $uibModal, $confirm, $filter, $cookies, $http, ngTranslate) {

        var vm = this;

        vm.updateData = updateData;
        vm.downloadFile = downloadFile;
        vm.deleteDocumentationFile = deleteDocumentationFile;
        var $translate = $filter('translate');

        vm.availableItemsPerPage = [{
            value: 20,
            label: "20"
        }, {
            value: 50,
            label: "50"
        }, {
            value: 100,
            label: "100"
        }];
        vm.itemsPerPage = vm.availableItemsPerPage[pagingParams.itemsPerPage];
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.filter = {
            memberName: null,
            memberCode: null
        };

        loadAll();

        function loadAll() {
            var queryParams = {
                page: pagingParams.page - 1,
                size: vm.itemsPerPage.value,
                sort: sort()
            };
            // if (_refresh) {
            //     queryParams = cookiePagingParams;
            //     var splited = queryParams.sort[0].split(',');
            //     vm.predicate = splited[0];
            //     vm.reverse = splited[1];
            // }
            $cookies.putObject('documentationParams', queryParams);
            Documentation.query(queryParams, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                _.each(data, function (item) {
                    item.name = decodeURI(item.name);
                });
                vm.documents = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function updateData() {
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }


        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                itemsPerPage: vm.findIndexItemsPerPage()
            });
        }

        vm.findIndexItemsPerPage = function () {
            for (var i = 0; i < vm.availableItemsPerPage.length; i++) {
                if (vm.availableItemsPerPage[i].value === vm.itemsPerPage.value) {
                    return i;
                }
            }
            return 0;
        };

        vm.changeItemsPerPage = function (item) {
            vm.itemsPerPage = item;
            vm.loadPage(1);
            loadAll();
        };


        function downloadFile(id, name) {
            $http({
                method: 'GET',
                url: 'gspserver/api/documentation/getDocumentationFile/' + id,
                responseType: 'arraybuffer'
            }).then(function(data) {
                var blob = new Blob([data.data]);
                saveAs(blob, name);
            });
        }

        function deleteDocumentationFile(doc) {
            $confirm({
                text: $translate('gspUserApp.documentation.deleteDocumentDialog.question') + ': ' + $translate('gspUserApp.documentation.documentNames.' + doc.documentName) + ' - ' + doc.name,
                title: $translate('gspUserApp.documentation.deleteDocumentDialog.title'),
                ok: $translate('gspUserApp.documentation.action.yes'),
                cancel: $translate('gspUserApp.documentation.action.no')
            }).then(function () {
                Documentation.deleteDocumentationFile({ id: doc.id }, doc, function (resp) {
                    _.forEach(vm.documents, function (doc, index) {
                        if (doc.id === resp.id) {
                            vm.documents[index] = resp;
                        }
                    });
                    AlertService.success("gspUserApp.documentation.alert.deleteFileSuccess", { id: doc.id });
                }, function (error) {
                    AlertService.error(error.data.message);
                });
            });
        }

    }
})();
