(function () {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('SubmissionHistoryController', SubmissionHistoryController);

    SubmissionHistoryController.$inject = ['$scope', '$uibModal', '$uibModalInstance', 'entity', 'decision', 'Submission','AlertService'];

    function SubmissionHistoryController($scope, $uibModal,$uibModalInstance, entity, decision, AlertService) {
        var vm = this;
        vm.clear = clear;
        vm.submission = entity;
        vm.decision = decision;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
