(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('password', {
            parent: 'reset',
            url: '/password',
            data: {
                authorities: ['LOGIN_USER']
            },
          onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
             $uibModal.open({
                        templateUrl: 'app/password/password.html',
                        controller: 'PasswordController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        keyboard: false,
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('password');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                    $state.go('auction', null, {
                        reload: true
                    });
                }, function() {
                    $state.go('auction');
                });
         }]
        })
        .state('phone-password', {
            parent: 'reset',
            url: '/phone-password',
            data: {
                authorities: ['LOGIN_USER']
            },
              onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
             $uibModal.open({
                        templateUrl: 'app/password/phone-password.html',
                        controller: 'PhonePasswordController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        keyboard: false,
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('password');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                    $state.go('auction', null, {
                        reload: true
                    });
                }, function() {
                    $state.go('auction');
                });
         }]
        })
        .state('password.reset', {
             parent: 'reset',
             url: '/password.reset?lang&token',
             data: {
                 authorities: []
             },
             params: {
                lang: 'en',
                token: null
             },
             onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                 $uibModal.open({
                     templateUrl: 'app/password/password-reset.html',
                     controller: 'PasswordResetController',
                     controllerAs: 'vm',
                     backdrop: 'static',
                     keyboard: false,
                     size: 'lg',
                     resolve: {
                         translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                             $translatePartialLoader.addPart('password');
                             return $translate.refresh();
                         }]
                     }
                 }).result.then(function() {
                     $state.go('login', null, {
                         reload: true
                     });
                 }, function() {
                     $state.go('login');
                 });
             }]
         });
    }
})();
