(function() {
    'use strict';
    angular
        .module('gspUserApp')
        .factory('BidSettlement', BidSettlement);

    BidSettlement.$inject = ['$resource', 'DateUtils'];

    function BidSettlement ($resource, DateUtils) {
        var resourceUrl =  'gspserver/' + 'api/bid-settlements/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            },
            'getWinningMembersByAuctionId': {
                method: 'GET',
                isArray: true,
                url: 'gspserver/api/bid-settlements/getWinningMembersByAuctionId/:auctionId'
            }
        });
    }
})();
