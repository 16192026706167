(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('AuctionController', AuctionController);

    AuctionController.$inject = ['$scope', '$state', '$timeout', 'Auction', 'ParseLinks', 'AlertService', 'pagingParams', 'cookiePagingParams', 'paginationConstants', '$cookies'];

    function AuctionController($scope, $state, $timeout, Auction, ParseLinks, AlertService, pagingParams, cookiePagingParams, paginationConstants, $cookies) {
        var vm = this;

        vm.availableItemsPerPage = [{
            value: 20,
            label: "20"
        }, {
            value: 50,
            label: "50"
        }, {
            value: 100,
            label: "100"
        }];
        vm.itemsPerPage = vm.availableItemsPerPage[pagingParams.itemsPerPage];
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.updateData = updateData;
        vm.from = $state.params.from;

        switch (vm.from) {
        case 'all':
            $state.current.ncyBreadcrumb.label = "gspUserApp.auction.home.titleAll";
            break;
        case 'running':
            $state.current.ncyBreadcrumb.label = "gspUserApp.auction.home.titleRunning";
            break;
        case 'my':
            $state.current.ncyBreadcrumb.label = "gspUserApp.auction.home.titleMy";
            break;
        }

        $scope.$on('backEvent', function(event, args) {
            $timeout(function() {
                loadAll('refresh');
            });
        });

        loadAll();

        function loadAll(_refresh) {
            var queryParams = {
                page: pagingParams.page - 1,
                size: vm.itemsPerPage.value,
                sort: sort(),
                from: vm.from
            };
            if (_refresh) {
                queryParams = cookiePagingParams;
                var splited = queryParams.sort[0].split(',');
                vm.predicate = splited[0];
                vm.reverse = splited[1];
            }
            $cookies.putObject('auctionParams', queryParams);
            Auction.query(queryParams, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.auctions = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function updateData() {
            loadAll();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                from: vm.from,
                itemsPerPage: vm.findIndexItemsPerPage()
            });
        }

        vm.findIndexItemsPerPage = function() {
            for (var i = 0; i < vm.availableItemsPerPage.length; i++) {
                if (vm.availableItemsPerPage[i].value === vm.itemsPerPage.value) {
                    return i;
                }
            }
            return 0;
        }

        vm.changeItemsPerPage = function(item) {
            vm.itemsPerPage=item;
            vm.loadPage(1);
            loadAll();
        };

        vm.getUserPhase = function(phase) {
            return (phase === "REQUIRES_CONFIRMATION" ? "FINISHED" : phase);
        };

        var refreshAuctionWatcher = $scope.$on("REFRESH_AUCTION", function(event, args) {
            if (args.auction.id) {
                updateData(true);
            }
        });
        $scope.$on('$destroy', refreshAuctionWatcher);

    }
})();
