(function () {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('AuctionDetailController', AuctionDetailController);

    AuctionDetailController.$inject = ['$scope', '$rootScope', 'entity', 'Auction', 'Report', 'AlertService', 'PaginationUtil', 'Bid', 'BidSettlement',
        'paginationConstants', '$uibModal', '$state', '$http', 'AuctionSettlement', 'Principal', 'PhaseUtils', '$translate', '$cookies', 'cookieToggleParams'
    ];

    function AuctionDetailController($scope, $rootScope, entity, Auction, Report, AlertService, PaginationUtil, Bid, BidSettlement,
        paginationConstants, $uibModal, $state, $http, AuctionSettlement, Principal, PhaseUtils, $translate, $cookies, cookieToggleParams) {
        var vm = this;
        var account;
        var auctionBidsCopy = [];

        vm.auction = entity;
        vm.auctionBids = [];
        vm.auctionSettlement = null;
        vm.phaseUtils = new PhaseUtils(vm.auction);
        vm.loadingBids = true;
        vm.login = null;
        vm.collateral = {};
        vm.account = {};
        vm.canAddBid = false;
        vm.usumButtonVisible = false;

        vm.toggle = {
            resultTitleElement: '',
            detailsTitleElement: '',
            auctionConfTitleElement: '',
            changeHeight: ''
        };
        if (cookieToggleParams !== null) {
            vm.toggle = cookieToggleParams;
        }

        vm.showAuctionResults = function () {
            return (vm.auction && (vm.auction.phase !== "PUBLISHED" && vm.auction.phase !== "NEW"));
        };

        vm.isPhaseSettled = function () {
            return (vm.auction && vm.auction.phase === "SETTLED");
        };

        var findWarehouse = function (_id) {
            Auction.getWarehouseInstallations().$promise.then(function (resp) {
                var warehouse = _.findWhere(resp, {
                    id: parseInt(_id)
                })
                vm.auction.warehouseInstallation = warehouse.name;
                vm.auction.eicCode = warehouse.codeEIC;
            });
        }
        findWarehouse(vm.auction.warehouseInstallationId);


        function getWinningMembers() {
            BidSettlement.getWinningMembersByAuctionId({
                auctionId: vm.auction.id
            }, function (resp) {
                var search = _.findWhere(resp, {
                    code: vm.account.memberCode
                });
                search ? vm.usumButtonVisible = true : null;
            }, null);
        }
        getWinningMembers();

        Principal.identity().then(function (_account) {
            vm.account = _account;
            vm.login = _account.login;
        });

        vm.updateToggle = function () {
            $cookies.putObject('auctionToggle', vm.toggle);
        };


        var unsubscribe = $rootScope.$on('gspUserApp:auctionUpdate', function (event, result) {
            vm.auction = result;
            findWarehouse(vm.auction.warehouseInstallationId);
        });
        $scope.$on('$destroy', unsubscribe);

        function hasBids() {
            return vm.auctionBids && vm.auctionBids.length > 0;
        }

        function hasBidSettlements() {
            return vm.auctionSettlement && vm.auctionSettlement.bidSettlements && vm.auctionSettlement.bidSettlements.length > 0;
        }

        vm.isFinished = (vm.auction && vm.auction.phase === "FINISHED") ? true : false;

        vm.getColspan = (vm.isFinished) ? 9 : 10;

        vm.getBordered = (vm.isFinished) ? 'bordered' : "";


        vm.page = 1;
        vm.sort = 'id,desc';
        vm.predicate = PaginationUtil.parsePredicate(vm.sort);
        vm.reverse = PaginationUtil.parseAscending(vm.sort);



        vm.sortBy = function (_propertyName) {
            vm.reverseLocal = (vm.propertyName === _propertyName) ? !vm.reverseLocal : false;
            vm.propertyName = _propertyName;
        };

        vm.loadMore = function () {
            vm.page++;
            vm.loadAllBids();
        };

        vm.loadAllBidsSorted = function () {
            vm.page = 1;
            vm.loadAllBids(true);
        };

        vm.loadAllBids = function (_sort, _algorithm) {

            _sort ? loadData() : loadDataByAlgorithm();

            function loadDataByAlgorithm() {
                Bid.getBidsSortedByAlgorithm({
                    auctionID: vm.auction.id,
                    page: vm.page - 1,
                    size: paginationConstants.itemsPerPage,
                }, onSuccess, onError);
            }

            function loadData() {
                Bid.query({
                    auctionID: vm.auction.id,
                    page: vm.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                if (_sort || _algorithm) {
                    auctionBidsCopy = data;
                } else {
                    for (var i = 0; i < data.length; i++) {
                        if (!_.where(auctionBidsCopy, data[i]).length) {
                            auctionBidsCopy.push(data[i]);
                        }
                    }
                }
                auctionBidsCopy = _.uniq(auctionBidsCopy, function (item, key, a) {
                    return item.id;
                });
                vm.auctionBids = auctionBidsCopy;
                vm.page = vm.page;
                vm.loadingBids = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingBids = false;
            }
        };

        function getAuctionSettlement() {
            // if (!vm.phaseUtils.isSettled) {
            //     return;
            // }
            vm.loadingBids = true;
            AuctionSettlement.get({
                auctionId: vm.auction.id
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.auctionSettlement = data;
                vm.loadingBids = false;
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.loadingBids = false;
            }
        }
        getAuctionSettlement();
        if (vm.phaseUtils.couldHaveBids || vm.phaseUtils.isInvalidatedOrInvalidatedDistributed)
            vm.loadAllBids();

        vm.openBidModal = function () {
            $uibModal.open({
                templateUrl: 'app/entities/bid/bid-dialog.html',
                controller: 'BidDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Auction', '$stateParams', function (Auction, $stateParams) {
                        return {
                            memberId: null,
                            userId: null,
                            lastUpdatedTime: null,
                            allowances: null,
                            price: null,
                            id: null,
                            auction: Auction.get({
                                'id': $stateParams.id
                            })
                        };
                    }],
                    injectCollateral: [function () {
                        return vm.collateral;
                    }],
                    injectAuctionId: [function () {
                        return vm.auction.id;
                    }]
                }
            }).result.then(function () {
                $state.go('auction-detail', {
                    id: vm.auction.id
                });
            }, function () {
                $state.go('auction-detail', {
                    id: vm.auction.id
                });
            });
        };

        vm.openBidModalForEdit = function (bidID) {
            $uibModal.open({
                templateUrl: 'app/entities/bid/bid-dialog.html',
                controller: 'BidDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Bid', function (Bid) {
                        return Bid.get({
                            'id': bidID
                        });
                    }],
                    injectCollateral: [function () {
                        return vm.collateral;
                    }],
                    injectAuctionId: [function () {
                        return vm.auction.id;
                    }]
                }
            }).result.then(function () {
                $state.go('auction-detail', {
                    id: vm.auction.id
                });
            }, function () {
                Bid.unlock({
                    'id': bidID
                }, null, function (result) {});
                $state.go('auction-detail', {
                    id: vm.auction.id
                });
            });
        };

        vm.cancelAllBids = function () {
            Bid.cancelCurrentUserBidsForAuction({
                auctionID: vm.auction.id
            }, function (response) {
                vm.loadAllBidsSorted();
            }, function (error) {
                AlertService.error(error.data.message);
            });
        };

        vm.openDeleteBidModal = function (bid) {
            $uibModal.open({
                templateUrl: 'app/entities/bid/bid-delete-dialog.html',
                controller: 'BidDeleteController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: [function () {
                        return bid;
                    }]
                }
            }).result.then(function () {
                $state.go('auction-detail', {
                    id: vm.auction.id
                }, {
                    reload: true
                });
            }, function () {
                $state.go('auction-detail', {
                    id: vm.auction.id
                }, {
                    reload: false
                });
            });
        };

        vm.isButtonCancelMyBidsVisible = function () {
            return vm.phaseUtils && vm.phaseUtils.isRunning && hasBids();
        }

        vm.canEditBid = function (bid) {
            return (bid && bid.memberCode === vm.account.memberCode) && (vm.auction && vm.phaseUtils.isRunning);
        }

        vm.shouldShowBids = function () {
            return hasBids() && vm.phaseUtils.couldHaveBids;
        }

        vm.shouldShowBidsEmpty = function () {
            return !hasBids() && vm.phaseUtils.couldHaveBids;
        }

        vm.shouldShowBidSettlements = function () {
            return vm.phaseUtils.isSettled && hasBidSettlements();
        };

        vm.shouldShowBidSettlementsEmpty = function () {
            return vm.phaseUtils.isSettled && !hasBidSettlements();
        };

        vm.openReportsWindow = function () {
            $uibModal.open({
                templateUrl: 'app/entities/auction/auction-reports.html',
                controller: 'AuctionReportsController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['Auction', function (Auction) {
                        return Auction.get({
                            id: vm.auction.id
                        }).$promise;
                    }]
                }
            }).result.then(function () {
                $state.go('auction-detail', {
                    id: vm.auction.id
                }, {
                    reload: false
                });
            }, function () {
                $state.go('auction-detail', {
                    id: vm.auction.id
                }, {
                    reload: false
                });
            });
        }

        vm.isReportButtonVisible = function () {
            return (vm.auction.phase === "RUNNING" || vm.auction.phase === "FINISHED" || vm.auction.phase === "INVALIDATED" || vm.auction.phase === "SETTLED" );
        }

        $scope.$on("REFRESH_BIDS", function (event, args) {
            if (args.auction.id == vm.auction.id) {
                getAuctionSettlement();
                getWinningMembers();
                vm.loadAllBids(false, true);
            }
        });

        $scope.$on("REFRESH_AUCTION", function (event, args) {
            if (args.auction.id === vm.auction.id) {
                Auction.get({ id: vm.auction.id }).$promise.then(function (resp) {
                    vm.auction = resp;
                });
                getAuctionSettlement();
                getWinningMembers();
                vm.loadAllBids(false, true);
            }
        });

        vm.getRemainingAllowances = function () {
            return vm.canAddBid ? vm.collateral.remainingAllowances : 0;
        };

        vm.getRemainingBids = function () {
            return vm.canAddBid ? vm.collateral.remainingBids : 0;
        };

        function b64toBlob(b64Data, contentType, sliceSize) {
            contentType = contentType || '';
            sliceSize = sliceSize || 512;

            var byteCharacters = atob(b64Data);
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, {
                type: contentType
            });
            return blob;
        }

        vm.generateUsum = function(){
            Report.getForAuctionUsum({
                "auctionId": vm.auction.id,
                "type": 'USUM_REPORT',
                "format": 'XLS',
                "members": vm.account.memberId,
                "langKey": $translate.storage().get('NG_TRANSLATE_LANG_KEY')
            }, function(data, headers) {
                var blob = b64toBlob(data.data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                saveAs(blob, data.fileName);
            }, function(error) {
                AlertService.error("gspUserApp.auction.usumError");
            });
        }
    }
})();
