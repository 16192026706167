(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('MenuController', MenuController);

    MenuController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'AlertService',
        '$uibModal', 'NotificationSocket', 'User', '$rootScope'
    ];

    function MenuController($state, Auth, Principal, ProfileService, LoginService, AlertService,
        $uibModal, NotificationSocket, User, $rootScope) {

        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.settingsAccount = null;

        Principal.identity().then(function(account) {
            vm.settingsAccount = account;
        });

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerDisabled = response.swaggerDisabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.showAdminMenu = false;
        vm.collapseAdminMenu = collapseAdminMenu;

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            $rootScope.loggedOutByUser = true;
            User.logoutCurrent({}, function(result) {
                collapseNavbar();
                Auth.logout();
                NotificationSocket.disconnect();
                $state.go('login');
            });
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function collapseAdminMenu() {
            vm.showAdminMenu = !vm.showAdminMenu;
        }

        vm.sendMessage = function() {
            $uibModal.open({
                templateUrl: 'app/entities/notification/notification-dialog.html',
                controller: 'NotificationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('notification');
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return {
                            type: null,
                            date: null,
                            content: null,
                            params: null,
                            id: null
                        };
                    }
                }
            });
        };

        vm.addOrderLog = function() {
            $uibModal.open({
                templateUrl: 'app/entities/report/order-log.html',
                controller: 'OrderLogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('auction');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return {
                            type: null,
                            date: null,
                            content: null,
                            params: null,
                            id: null
                        };
                    }
                }
            });
        }

        vm.showActivityMonitor = function() {
            $uibModal.open({
                templateUrl: 'app/entities/event/activity-monitor.html',
                controller: 'ActivityMonitorController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('event');
                        return $translate.refresh();
                    }]
                }
            });
        };

        vm.showHelp = function() {
            $uibModal.open({
                templateUrl: 'app/entities/help/help.html',
                controller: 'HelpController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
        };

        vm.password = function() {
            $uibModal.open({
                templateUrl: 'app/password/password.html',
                controller: 'PasswordController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('password');
                        return $translate.refresh();
                    }]
                }
            });
        };

        vm.isSystemAdministrationActive = function() {
            return vm.$state.includes('member') ||
                vm.$state.includes('user') ||
                vm.$state.includes('client') ||
                vm.$state.includes('supervision') ||
                vm.$state.includes('charges');
        };
    }
})();
