(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .filter('customNumber', ['$filter', function($filter) {

            String.prototype.replaceAll = function(search, replacement) {
                var target = this;
                return target.replace(new RegExp(search, 'g'), replacement);
            };

            var replaceCommaBySpace = function(amount) {
                var result = String(angular.copy(amount));
                result = result.replaceAll(',', ' ');
                result = result.replace('.', ',');
                return result;
            };

            return function(amount) {
                var result = $filter('number')(amount);

                return (amount == null) ? amount : replaceCommaBySpace(result);
            };
        }]);

})();
