(function() {
    'use strict';
    angular
        .module('gspUserApp')
        .factory('Bid', Bid);

    Bid.$inject = ['$resource', 'DateUtils'];

    function Bid ($resource, DateUtils) {
        var resourceUrl =  'gspserver/' + 'api/bids/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.lastUpdatedTime = DateUtils.convertDateTimeFromServer(data.lastUpdatedTime);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.lastUpdatedTime = DateUtils.convertLocalDateToServer(data.lastUpdatedTime);
                    data.auction.startTime = DateUtils.removeTimeZone(data.startTime);
                    data.auction.finishTime = DateUtils.removeTimeZone(data.finishTime);
                    data.auction.gasDayFrom = DateUtils.removeTimeZone(data.gasDayFrom);
                    data.auction.gasDayTo = DateUtils.removeTimeZone(data.gasDayTo);
                    return angular.toJson(data);
                }
            },
            'delete': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.auction.startTime = DateUtils.removeTimeZone(data.startTime);
                    data.auction.finishTime = DateUtils.removeTimeZone(data.finishTime);
                    data.auction.gasDayFrom = DateUtils.removeTimeZone(data.gasDayFrom);
                    data.auction.gasDayTo = DateUtils.removeTimeZone(data.gasDayTo);
                    return angular.toJson(data);
                },
                url: 'gspserver/api/bids/delete'
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.lastUpdatedTime = DateUtils.convertLocalDateToServer(data.lastUpdatedTime);
                    data.auction.startTime = DateUtils.removeTimeZone(data.startTime);
                    data.auction.finishTime = DateUtils.removeTimeZone(data.finishTime);
                    data.auction.gasDayFrom = DateUtils.removeTimeZone(data.gasDayFrom);
                    data.auction.gasDayTo = DateUtils.removeTimeZone(data.gasDayTo);
                    return angular.toJson(data);
                }
            },
            'cancelCurrentUserBidsForAuction': {
                method: 'DELETE',
                url: 'gspserver/api/auction/:auctionID/current-user-bids'
            },
            'lock': {
                method: 'PUT',
                url: 'gspserver/api/bids/:id/lock'
            },
            'unlock': {
                method: 'PUT',
                url: 'gspserver/api/bids/:id/unlock'
            },
            'getBidsSortedByAlgorithm': {
                method: 'GET',
                isArray: true,
                url: 'gspserver/api/bids/sorted-by-algorithm'
            }
        });
    }
})();
