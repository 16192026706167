(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .factory('PhonePassword', PhonePassword);

    PhonePassword.$inject = ['$resource'];

    function PhonePassword($resource) {
        var service = $resource('gspidentity/api/account/change_phone_password', {}, {});

        return service;
    }
})();
