(function() {
    'use strict';
    angular
        .module('gspUserApp')
        .factory('Dictionary', Dictionary);

    Dictionary.$inject = ['$resource'];

    function Dictionary ($resource) {
        var resourceUrl =  'gspserver/' + 'api/dict';

        return $resource(resourceUrl, {}, {
            'getAllocationAlgorithms': {
                method: 'GET',
                url: resourceUrl + '/allocationAlgorithms',
                isArray: true,
                transformResponse: function (data) {
                    var resp = [];
                    if (data) {
                        data = angular.fromJson(data);
                        _.map(data, function(option){ resp.push({name: 'gspUserApp.offer.allocationAlgorithm.'+option, id: option}); });
                    }
                    return resp;
                }
            },
            'getClusteringMethods': {
                method: 'GET',
                url: resourceUrl + '/clusteringMethods',
                isArray: true,
                transformResponse: function (data) {
                    var resp = [];
                    if (data) {
                        data = angular.fromJson(data);
                        _.map(data, function(option){ resp.push({name: 'gspUserApp.offer.clusteringMethods.'+option, id: option}); });
                    }
                    return resp;
                }
            },
            'getUsumTypes': {
                method: 'GET',
                url: resourceUrl + '/usumTypes',
                isArray: true,
                transformResponse: function (data) {
                    var resp = [];
                    if (data) {
                        data = angular.fromJson(data);
                        _.map(data, function(option){ resp.push({name: 'gspUserApp.offer.usumTypes.'+option, id: option}); });
                    }
                    return resp;
                }
            },
            'getPurposes': {
                method: 'GET',
                url: resourceUrl + '/purposes',
                isArray: true,
                transformResponse: function (data) {
                    var resp = [];
                    if (data) {
                        data = angular.fromJson(data);
                        _.map(data, function(option){ resp.push({name: 'gspUserApp.offer.purposes.'+option, id: option}); });
                    }
                    return resp;
                }
            },
            'getServiceTypes': {
                method: 'GET',
                url: resourceUrl + '/serviceTypes',
                isArray: true,
                transformResponse: function (data) {
                    var resp = [];
                    if (data) {
                        data = angular.fromJson(data);
                        _.map(data, function(option){ resp.push({name: 'gspUserApp.offer.serviceType.'+option, id: option}); });
                    }
                    return resp;
                }
            },
            'getWarehouseInstallationDictionary': {
                method: 'GET',
                url: resourceUrl + '/warehouseInstallations',
                isArray: true
            },
            'getSecurityForms': {
                method: 'GET',
                url: resourceUrl + '/securityForms',
                isArray: true,
                transformResponse: function (data) {
                    var resp = [];
                    if (data) {
                        data = angular.fromJson(data);
                        _.map(data, function(option){ resp.push({name: 'gspUserApp.offer.securityForms.'+option, id: option}); });
                    }
                    return resp;
                }
            },
            'getProcessCategories': {
                method: 'GET',
                url: resourceUrl + '/processCategories',
                isArray: true,
                transformResponse: function (data) {
                    var resp = [];
                    if (data) {
                        data = angular.fromJson(data);
                        _.map(data, function(option){ resp.push({name: 'gspUserApp.documentation.processCategories.'+option, id: option}); });
                    }
                    return resp;
                }
            },
            'getProcessTypesByProcessCategory': {
                method: 'GET',
                url: resourceUrl + '/getProcessTypesByProcessCategory',
                isArray: true,
                transformResponse: function (data) {
                    var resp = [];
                    if (data) {
                        data = angular.fromJson(data);
                        _.map(data, function(option){ resp.push({name: 'gspUserApp.documentation.processTypes.'+option, id: option}); });
                    }
                    return resp;
                }
            },
            'getDocumentNamesByProcessCategoryAndProcessTypes': {
                method: 'GET',
                url: resourceUrl + '/getDocumentNamesByProcessCategoryAndProcessTypes',
                isArray: true,
                transformResponse: function (data) {
                    var resp = [];
                    if (data) {
                        data = angular.fromJson(data);
                        _.map(data, function(option){ resp.push({name: 'gspUserApp.documentation.documentNames.'+option, id: option}); });
                    }
                    return resp;
                }
            },
            'getDocumentTypesByDocumentName': {
                method: 'GET',
                url: resourceUrl + '/getDocumentTypesByDocumentName',
                isArray: true,
                transformResponse: function (data) {
                    var resp = [];
                    if (data) {
                        data = angular.fromJson(data);
                        _.map(data, function(option){ resp.push({name: 'gspUserApp.documentation.documentTypes.'+option, id: option}); });
                    }
                    return resp;
                }
            }
        });
    }
})();
