(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .filter('price', ['$filter', price]);

    function price ($filter) {
        return priceFilter;

        function priceFilter(input) {
            return $filter('currency')(input, '', 2);
        }
    }
})();
