(function() {
    angular
        .module('gspUserApp')
        .directive('selectize', selectize)
        .value('selectizeConfig', {
            create: false,
            valueField: 'id',
            labelField: 'name',
            maxItems: 1,
            allowEmptyOption: false,
            dropdownParent: 'body',
            render: {
                option_create: function(data, escape) {
                    return '<div class="create"><span class="glyphicon glyphicon-plus"></span> <strong>' + escape(data.input) + '</strong>&hellip;</div>';
                }
            },
        });
    selectize.$inject = ['selectizeConfig', '$translate', '$timeout', 'Principal'];

    function selectize(selectizeConfig, $translate, timeout, Principal) {
        var directive = {
            require: '?ngModel',
            scope: {
                ngModel: '=',
                config: '=?',
                options: '=?',
                ngDisabled: '=',
                ngRequired: '&',
                ngChange: '&',
                onDropdownClose: '&',
                translateOptions: '@'
            },
            controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {

            }],
            link: link,
            restrict: 'AE'
        };
        return directive;

        function link(scope, element, attrs, modelCtrl) {

            if (scope.config && scope.config.plugins && _.includes(scope.config.plugins,'remove_button')){
                var dummyPlugins = {};
                _.each(scope.config.plugins,function(plugin){
                    dummyPlugins[plugin] = {};
                    if (plugin === 'remove_button'){
                        dummyPlugins[plugin] = { title: $translate.instant('entity.action.delete')};
                    }
                });
                scope.config.plugins = dummyPlugins;
            }

            var selectize;
            var settings = angular.extend({}, Selectize.defaults, selectizeConfig, scope.config);
            var optionsTable = [];
            var wrongValue;

            var optionsCollectionWatcher;
            var optionsWatcher;
            var modelWatcher;
            var isDisabledWatched;

            scope.options = scope.options || [];
            scope.config = scope.config || {};

            if (scope.config.inputMaxlength) {
                Selectize.define('inputMaxlength', function(options) {
                    var self = this;
                    this.setup = (function() {
                        var original = self.setup;
                        return function() {
                            original.apply(this, arguments);
                            this.$control_input.attr('maxlength', this.settings.inputMaxlength);
                        };
                    })();
                });
            }

            optionsWatcher = scope.$watch('options', (function() {
                if (scope.translateOptions == 'true') {
                    for (var index = 0; index < scope.options.length; index++) {
                        var name = scope.options[index].name;
                        optionsTable.push(name);
                    }
                    $translate(optionsTable).then(function(translations) {
                        for (var index = 0; index < scope.options.length; index++) {
                            if(translations[scope.options[index].name] != null) {
                                scope.options[index].name = translations[scope.options[index].name];
                            }
                        }
                        setSelectizeValue(null, true);
                    });
                } else {
                    setSelectizeValue(null, true);
                }
            }));


            var isEmpty = function(val) {
                return val === undefined || val === null || !val.length; //support checking empty arrays
            };

            var toggle = function(disabled) {
                disabled ? selectize.disable() : selectize.enable();
            };

            var filterAuthorities = function(authority){
                if (!_.isUndefined(authority)) {
                    Principal.hasAuthority(authority)
                        .then(function (result) {
                            if (result) {
                                selectize.enable();
                            } else {
                                selectize.disable();
                            }
                        });
                }
            };

            var addWrongValueOption = function(value)  {
                var option = {};
                option[settings.valueField] = value;
                option[settings.labelField] = $translate.instant('adminApp.error.wrongValue');

                timeout(function() {
                    selectize.addOption(option);
                });
            };

            var isModelValueCorrect = function() {
                var isEveryValueInOptions;

                if ( settings.maxItems === 1 ) {
                    isEveryValueInOptions = isValueInOptions(scope.ngModel);
                }
                else {
                    isEveryValueInOptions = !_.some(scope.ngModel, function(value) {
                        return isValueInOptions(value) ? false : true;
                    });
                }

                function isValueInOptions(value) {
                    var isValueInOptions = _.some(scope.options, function(option) {
                        return option[settings.valueField].toString() === value.toString();
                    });

                    if (!isValueInOptions) {
                        addWrongValueOption(value);
                        wrongValue = value;
                    }

                    if (wrongValue === value) {
                        return false;
                    }

                    return isValueInOptions;
                }
                return isEveryValueInOptions;
            };

            var validate = function() {
                var isModelEmpty = isEmpty(scope.ngModel);
                var isInvalid = (scope.ngRequired() || attrs.required || settings.required) && isModelEmpty;
                modelCtrl.$setValidity('required', !isInvalid);

                // modelCtrl.$setValidity("correctValue", (!isModelEmpty && scope.options.length) ? isModelValueCorrect() : true );
            };

            var setSelectizeOptions = function(curr, prev) {
                if (curr) {
                    angular.forEach(prev, function(opt) {
                        if (curr.indexOf(opt) === -1) {
                            var value = opt[settings.valueField];
                            selectize.removeOption(value, true);
                            selectize.blur();
                        }
                    });

                    selectize.addOption(curr, true);

                    setSelectizeValue();
                }
                preselectOnSingleOptionAndEmptyModel();
            };

            var setSelectizeValue = function(values, set) {
                validate();

                selectize.$control.toggleClass('ng-valid', modelCtrl.$valid);
                selectize.$control.toggleClass('ng-invalid', modelCtrl.$invalid);
                selectize.$control.toggleClass('ng-dirty', modelCtrl.$dirty);
                selectize.$control.toggleClass('ng-pristine', modelCtrl.$pristine);

                if (!angular.equals(selectize.items, scope.ngModel)) {
                    timeout(function() {
                        if ((scope.options && scope.options.length > 0 && scope.ngModel) || set) {
                            selectize.setValue(scope.ngModel || scope.oldModel);
                        }
                    });
                }
            };

            function preselectOnSingleOptionAndEmptyModel() {
                if (!settings.preselectOnEmptyModelAndSingleOption) {
                    return;
                }
                if (_.isNil(scope.ngModel) && _.size(scope.options) == 1) {
                    scope.ngModel = scope.options[0][settings.valueField];
                    console.log("Preselecting model....");
                }
            }

            settings.onChange = function() {
                if (scope.options.length > 0) {
                    scope.oldModel = null;
                }

                var value = angular.copy(selectize.items);
                if (settings.maxItems == 1) {
                    value = value[0]
                }
                modelCtrl.$setViewValue(value);

                if (scope.config.onChange) {
                    scope.ngChange();
                    scope.config.onChange.apply(this, arguments);
                }
            };

            settings.onDropdownClose = function() {
                if (scope.onDropdownClose) {
                    scope.onDropdownClose();
                }
            };

            settings.onOptionAdd = function(value, data) {
                if (scope.options.indexOf(data) === -1) {
                    scope.options.push(data);

                    if (scope.config.onOptionAdd) {
                        scope.config.onOptionAdd.apply(this, arguments);
                    }
                }
            };

            settings.onInitialize = function() {
                selectize = element[0].selectize;
                scope.oldModel = angular.copy(scope.ngModel);

                setSelectizeOptions(scope.options);

                //provides a way to access the selectize element from an
                //angular controller
                if (scope.config.onInitialize) {
                    scope.config.onInitialize(selectize);
                }

                optionsCollectionWatcher = scope.$watchCollection('options', setSelectizeOptions);
                modelWatcher = scope.$watch('ngModel', setSelectizeValue);
                isDisabledWatched = scope.$watch('ngDisabled', toggle);
                filterAuthorities(scope.config.requiredAuthority);
            };

            element.selectize(settings);

            element.on('$destroy', function() {
                if (selectize) {
                    selectize.destroy();
                    element = null;
                }
            });

            scope.$onDestroy = function() {
                optionsCollectionWatcher();
                optionsWatcher();
                modelWatcher();
                isDisabledWatched();
            };
        }
    }
})();
