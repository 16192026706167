(function() {
    'use strict';
    angular
        .module('gspUserApp')
        .factory('Submission', Submission);

    Submission.$inject = ['$resource', 'DateUtils'];

    function Submission ($resource, DateUtils) {
        var resourceUrl =  'gspserver/' + 'api/submissions';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method:'PUT',
                transformRequest: function (data) {
                    if (data) {
                        var copy = angular.copy(data);
                        copy.startDate = DateUtils.removeTimeZone(copy.startDate);
                    }
                    return angular.toJson(copy);
                }
            },
            'save': {
                method:'POST',
                transformRequest: function (data) {
                    if (data) {
                        var copy = angular.copy(data);
                        copy.startDate = DateUtils.removeTimeZone(copy.startDate);
                    }
                    return angular.toJson(copy);
                }
            },
            'delete': {
                method: 'PUT',
                url: 'gspserver/api/auctions/delete'
            },
            'generateSubmissionProfiles': {
                method: 'GET',
                isArray: true,
                url: 'gspserver/api/submissions/generateSubmissionProfiles',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getSubmissionById': {
                method: 'GET',
                url: 'gspserver/api/submissions/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                    }
                    return data;
                }
            },
            'getAvailableStartDatesToSubmission': {
                method: 'GET',
                isArray: true,
                url: 'gspserver/api/submissions/getAvailableStartDatesToSubmission',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getSubmissionsByOfferId': {
                method: 'GET',
                isArray: true,
                url: 'gspserver/api/submissions/getSubmissionsByOfferId',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAvailableServiceDurations': {
                method: 'GET',
                isArray: true,
                url: resourceUrl + '/getAvailableServiceDurations'
            },
            'operation': {
                method: 'POST',
                url: 'gspserver/api/submissions/submission-operation'
            },
            'getAvailableProductConfigurations': {
                isArray: true,
                method: 'GET',
                url: 'gspserver/api/productConfiguration/getAvailableProductConfigurations'
            },
            'getValidationParameterList': {
                isArray: true,
                method: 'GET',
                url: 'gspserver/api/productConfiguration/getValidationParameterList:productConfigurationId'
            },
            'generateSSAForm': {
                method: 'GET',
                url: resourceUrl + '/:submissionId/SSAForm'
            },
            'takeAllocationDecision': {
                method: 'POST',
                url: resourceUrl + '/allocation-decision?submissionId=:submissionId'
            },
            'confirmAllocationDecision': {
                method: 'POST',
                url: resourceUrl + '/allocation-decision-confirm?submissionId=:submissionId'
            },
            'getAllocationDecisionHistoryBySubmissionId': {
                method: 'GET',
                isArray: true,
                url: 'gspserver/api/submissions/getAllocationDecisionHistoryBySubmissionId/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getSubmissionsByMemberOfLoggedInUser': {
                method: 'GET',
                url: resourceUrl + '/byMemberOfLoggedInUser',
                isArray: true
            },
            'getContactPeople': {
                isArray: true,
                method: 'GET',
                params: {
                    isAdmin: false
                },
                url: 'gspidentity/api/users/getUsersForDict'
            }
        });
    }
})();
