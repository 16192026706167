(function () {
    'use strict';
    angular
        .module('gspUserApp')
        .factory('Auction', Auction);

    Auction.$inject = ['$resource', 'DateUtils'];

    function Auction($resource, DateUtils) {
        var resourceUrl = 'gspserver/' + 'api/auctions/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startTime = DateUtils.convertDateTimeFromServer(data.startTime);
                        data.finishTime = DateUtils.convertDateTimeFromServer(data.finishTime);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'getWarehouseInstallations':{
                method: 'GET',
                url: '/gspserver/api/dict/warehouseInstallations',
                isArray: true
            }
        });
    }
})();
