(function () {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('SubmissionDialogController', SubmissionDialogController);

    SubmissionDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'baseCluster', 'offer', 'Submission', 'Auction', 'Offer', 'Navbar', 'Dictionary', 'DateUtils', 'AlertService', 'Principal'];

    function SubmissionDialogController($timeout, $scope, $uibModalInstance, entity, baseCluster, offer, Submission, Auction, Offer, Navbar, Dictionary, DateUtils, AlertService, Principal) {
        var vm = this;
        vm.submission = entity;

        if (vm.submission.id) vm.editMode = true;
        if (vm.submission.status === 'TO_IMPROVE') vm.toImproveStatus = true;

        vm.offer = offer;
        if (baseCluster) {
            vm.baseCluster = baseCluster;
            vm.initialClusterId = baseCluster.id;
        }
        vm.productConfigurationId = null;
        vm.datePickerStartDateOptions = {};
        vm.getAvailableProducts = getAvailableProducts;
        vm.getAvailableServiceDurations = getAvailableServiceDurations;
        vm.generateSubmissionProfile = generateSubmissionProfile;
        vm.changeAgreementType = changeAgreementType;
        vm.changeProductType = changeProductType;
        vm.changeReduction = changeReduction;
        vm.conditionCopyValue = conditionCopyValue;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.validationErrorIndex = null;
        vm.validationError = null;
        vm.validationErrorMax = null;
        vm.emptyStartDates = false;
        vm.generatreProfile = true;
        vm.temp;
        vm.selectizeConfig = {
            create: false,
            valueField: 'id',
            labelField: 'name',
            allowEmptyOption: false
        };

        vm.$onInit = function () {
            vm.selected = _.map(vm.submission.contactPeople, function (v) {
                return v.userId.toString();
            })
        }

        vm.multiSelectizeConfig = {
            create: false,
            valueField: 'id',
            labelField: 'name',
            allowEmptyOption: false,
            plugins: ['remove_button'],
            delimiter: ',',
            persist: true,
            maxItems: 99999
        };

        Principal.identity().then(function(account) {
            vm.submission.memberCode = account.memberCode;
            vm.submission.memberId = account.memberId;
            vm.submission.username = account.login;
        });

        vm.canEditSubmissionReductionPermit = Principal.hasAuthority("CAN_EDIT_SUBMISSION_REDUCTION_PERMIT");
        vm.submission.reductionPermit = true;

        if (vm.editMode && vm.submission.status === 'TO_IMPROVE') {
            vm.purposes = [{ name: 'gspUserApp.offer.purposes.'+vm.submission.purpose , id: vm.submission.purpose }];
            vm.agreementTypes = [{ name: 'gspUserApp.offer.agreementsShort.'+vm.submission.agreementType , id: vm.submission.agreementType }];
            // vm.products = [{ name: 'gspUserApp.offer.productTypes.'+vm.submission.product , id: vm.submission.product }];
            vm.startDatesList = [{ name: DateUtils.convertLocalDateToCustom(new Date(vm.submission.startDate), 'yyyy-MM-dd'),  id: vm.submission.startDate }];

            getAvailableProducts(vm.submission.agreementType, vm.submission.purpose);
            // var selectedProduct = _.find(vm.productsList, {
            //     product: vm.submission.product
            // });
            // getValidationParameters(selectedProduct);

        } else {
            Offer.getAgreementTypesByClusterId({ clusterId: vm.initialClusterId }).$promise.then(function (resp) {
                vm.agreementTypes = [];
                _.map(resp, function(option){ vm.agreementTypes.push({name: 'gspUserApp.offer.agreementsShort.'+option, id: option}); });
            });

            Offer.getPurposesByClusterId({ clusterId: vm.initialClusterId }).$promise.then(function (resp) {
                vm.purposes = [];
                _.map(resp, function(option){ vm.purposes.push({name: 'gspUserApp.offer.purposes.'+option, id: option}); });
            });

            if (vm.editMode) {
                vm.changeAgreementType(vm.submission.agreementType, vm.submission.purpose, false);
                vm.changeProductType(vm.submission.product, vm.submission.startDate);

                // $timeout(function () {
                //     vm.submission.lastModifiedDate = new Date(vm.submission.lastModifiedDate);
                //
                //     vm.changeAgreementType(vm.submission.agreementType, vm.submission.purpose, false);
                //
                // }, 2000);
                //
                // $timeout(function () {
                //
                //     vm.changeProductType(vm.submission.product, vm.submission.startDate);
                // }, 2000);

            }
        }

        Dictionary.getSecurityForms().$promise.then(function (resp) {
            vm.securityForms = resp;
        });
        
        Submission.getContactPeople().$promise.then(function (resp) {
            vm.values = resp;
            if (!vm.submission.id) {
                vm.selected = vm.values;
                vm.selected = _.map(vm.selected, function (v) {
                    return v.id.toString();
                })
            }
        });

        function getAvailableProducts(agreementType, purpose) {
            if (agreementType && purpose) {
                Submission.getAvailableProductConfigurations({
                    offerId: baseCluster.offerId,
                    purpose: vm.submission.purpose,
                    agreementType: vm.submission.agreementType
                }).$promise.then(function (resp) {
                    vm.products = [];
                    vm.productsList = [];
                    _.map(resp, function(option){ vm.products.push({name: 'gspUserApp.offer.productTypes.'+option.product, id: option.product}); });
                    vm.productsList = resp;

                    var startDates = getAvailableStartDates(vm.submission.product, agreementType);
                    getAvailableServiceDurations(vm.submission.product, vm.submission.startDate);
                    if(!startDates) return;

                    var existProduct = _.find(vm.products, function(o) { return o.id === vm.submission.product; });
                    if(existProduct) {
                        if (vm.submission.product && vm.submission.startDate && vm.submission.serviceDuration) {
                            vm.submission.profiles = [];
                            generateSubmissionProfile(vm.submission.product, vm.submission.startDate, vm.submission.serviceDuration);
                        }
                    } else {
                        vm.submission.profiles = [];
                        vm.submission.product = null;
                    }
                });
            }
        }

        $scope.copyValFun=function(event){
            if(vm.submission.agreementType==='MONTHLY' && vm.offer.warehouseInstallationId!=2){
                 _.forEach(vm.submission.profiles, function (param) {
                    param.bundledUnits=event;
                 });
            }
        }

        function conditionCopyValue() {
            if(vm.submission.agreementType==='MONTHLY' && vm.offer.warehouseInstallationId!=2){
                return true;
            } else return false;
        }

        function changeAgreementType(agreementType, purpose, product, deleteDate) {
            if (purpose) getAvailableProducts(agreementType, purpose);
            //if (deleteDate) vm.submission.startDate = null;
            // if (deleteDate) vm.submission.serviceDuration = null;
        }


        function getAvailableStartDates(product, agreementType) {
            if (product && agreementType) {

                var selectedProduct = _.find(vm.productsList, {
                    product: product
                });

                Submission.getAvailableStartDatesToSubmission({
                    offerId: baseCluster.offerId,
                    productConfigurationId: selectedProduct.id
                }).$promise.then(function (resp) {

                    // if (!vm.editMode) vm.submission.startDate = null;

                    if (resp.length > 0) {
                        vm.emptyStartDates = false;
                        if (agreementType === 'ANNUAL') {
                            vm.startDatesList = [];
                            _.map(resp, function(option){ vm.startDatesList.push({
                                name: DateUtils.convertLocalDateToCustom(new Date(option), 'yyyy-MM-dd'),
                                id: option}); });

                        }

                        if (agreementType === 'MONTHLY') {
                            vm.startDatesList = [];
                            _.map(resp, function(option){ vm.startDatesList.push({
                                name: DateUtils.convertLocalDateToCustom(new Date(option), 'yyyy-MM-dd'),
                                id: option}); });
                        }

                        if (agreementType === 'WEEKLY') {
                            vm.datePickerStartDateOptions = {
                                format: "yyyy-MM-dd",
                                timepicker: false,
                                minDate: new Date(resp[0]),
                                maxDate: new Date(resp[1])
                            };
                        }
                        return true;
                    } else {
                        vm.startDatesList = [];
                        vm.submission.startDate = null;
                        vm.submission.profiles = null;
                        vm.validationParameters = null;
                        vm.submission.serviceDuration = null;
                        vm.emptyStartDates = true;
                        return false;
                    }
                });
            }
        }

        function changeProductType(product, startDate, agreementType) {
            if (product === 'DIVIDED_UM') {
                delete vm.submission.maxReductionPackage;
            }

            if (product !== 'DIVIDED_UM') {
                delete vm.submission.maxReductionInjectionPower;
                delete vm.submission.maxReductionReceiptPower;
                delete vm.submission.maxReductionActiveCapacity;
            }

            if (product) getAvailableServiceDurations(product, startDate);

            getAvailableStartDates(product, agreementType);

        }

        function changeReduction() {
            if (!vm.submission.reductionPermit) {
                delete vm.submission.maxReductionPackage;
                delete vm.submission.maxReductionInjectionPower;
                delete vm.submission.maxReductionReceiptPower;
                delete vm.submission.maxReductionActiveCapacity;
            }
        }

        function getAvailableServiceDurations(productType, startDate) {
            if (productType && startDate && vm.productsList) {
                var selectedProduct = _.find(vm.productsList, {
                    product: productType
                });

                Submission.getAvailableServiceDurations({
                    offerId: baseCluster.offerId,
                    startDate: DateUtils.removeTimeZone(vm.submission.startDate),
                    productConfigurationId: selectedProduct.id
                }).$promise.then(function (resp) {
                    vm.serviceDurations = [];
                    _.map(resp, function(option){ vm.serviceDurations.push({name: option, id: option}); });

                    var existServiceDuration = _.find(vm.serviceDurations, function(o) { return o.id === Number(vm.submission.serviceDuration); });

                    if (vm.submission.product && vm.submission.startDate && existServiceDuration) {
                        generateSubmissionProfile(productType, startDate, vm.submission.serviceDuration);
                    } else {
                        // vm.submission.profiles = [];
                        getValidationParameters(selectedProduct);
                    }

                    if (vm.editMode) {
                        vm.submission.serviceDuration = vm.submission.profiles.length;
                        vm.generatreProfile = false;
                    }
                });
            }
        }

        function generateSubmissionProfile(productType, startDate, serviceDuration) {
            if (productType && startDate && serviceDuration && vm.generatreProfile) {
                var selectedProduct = _.find(vm.productsList, {
                    product: productType
                });

                Submission.generateSubmissionProfiles({
                    startDate: DateUtils.removeTimeZone(vm.submission.startDate),
                    productConfigurationId: selectedProduct.id,
                    serviceDuration: serviceDuration,
                    offerId: vm.offer.id
                }).$promise.then(function (resp) {
                    vm.submission.profiles = resp;
                });

                getValidationParameters(selectedProduct);

            }

            vm.generatreProfile = true;
        }

        function getValidationParameters(selectedProduct) {
            Submission.getValidationParameterList({ productConfigurationId: selectedProduct.id }).$promise.then(function (resp) {
                vm.validationParameters = resp;

                vm.validationValues = {};

                _.forEach(vm.validationParameters, function (param) {
                    if (param.validationValues.length > 1) {
                        _.forEach(param.validationValues, function (item) {
                            vm.validationValues[param.parameterKind + '_' + item.valueType] = item.value;
                        });
                    } else {
                        vm.validationValues[param.parameterKind + '_' + param.validationValues[0].valueType] = param.validationValues[0].value;
                    }
                });
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.submission.id !== null) {
                updateModel();
                Submission.update(vm.submission, onSaveSuccess, onSaveError);
            } else {
                updateModel();
                Submission.save(vm.submission, onSaveSuccess, onSaveError);
            }
        }

        function updateModel() {
            var toSaved = [];
            if (vm.submission.id !== null) {
                for (var i = 0; i < vm.selected.length; i++) {
                    for (var j = 0; j < vm.submission.contactPeople.length; j++) {
                        if (vm.selected[i] == vm.submission.contactPeople[j].userId) {
                            toSaved.push(vm.submission.contactPeople[j]);
                        }
                    }
                }

                var selectedId = _.map(toSaved, function (v) {
                    return v.userId.toString();
                })


                for (var i = 0; i < vm.selected.length; i++) {
                    if (!selectedId.includes(vm.selected[i])) {
                        var te = { id: null, userId: vm.selected[i], name: null }
                        toSaved.push(te);
                    }
                }
                vm.submission.contactPeople = toSaved;
            } else {
                for (var i = 0; i < vm.selected.length; i++) {
                    var te = { id: null, userId: vm.selected[i], name: null }
                    toSaved.push(te);
                }
                vm.submission.contactPeople = toSaved;
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('gspUserApp:auctionUpdate', result);
            AlertService.success("gspUserApp.offer.alert.saveSubmissionSuccess", {
                id: result.id
            });
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError(error) {
            if (error.data.params && error.data.params.length > 2) {
                AlertService.error(error.data.message, {
                    index: error.data.params[0],
                    profile: error.data.params[1],
                    max: error.data.params[2]
                });

                vm.validationErrorIndex = error.data.params[0] - 1;
                vm.validationErrorMax = error.data.params[2];

                switch (error.data.message) {
                case 'error.security.submission.validation.tooMuchUnits':
                    vm.validationError = 'tooMuchUnits';
                    break;
                case 'error.security.submission.validation.tooMuchCapacity':
                    vm.validationError = 'tooMuchCapacity';
                    break;
                case 'error.security.submission.validation.tooMuchInjectionPower':
                    vm.validationError = 'tooMuchInjectionPower';
                    break;
                case 'error.security.submission.validation.tooMuchReceiptPower':
                    vm.validationError = 'tooMuchReceiptPower';
                    break;
                }
            } else {
                AlertService.error(error.data.message);
            }

            vm.isSaving = false;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.capacityNotMultipleOfUnit = function (capacityValue) {
            if (vm.validationValues && vm.validationValues['ACTIVE_CAPACITY_MULTIPLE']) {
                if (capacityValue % vm.validationValues['ACTIVE_CAPACITY_MULTIPLE'] !== 0) {
                    return true;
                }
            }
        };

        vm.checkProfileParameters = function (value, type, index) {
            return (value > vm.validationErrorMax && vm.validationError === type && vm.validationErrorIndex === index);
        };
    }
})();
