(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('BidSettlementDetailController', BidSettlementDetailController);

    BidSettlementDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity'];

    function BidSettlementDetailController($scope, $rootScope, $stateParams, entity) {
        var vm = this;

        vm.bidSettlement = entity;

        var unsubscribe = $rootScope.$on('gspUserApp:bidUpdate', function(event, result) {
            vm.bidSettlement = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
