(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', '$uibModal',
        'NotificationSocket', 'User', '$rootScope', '$interval', '$scope', 'Navbar'
    ];

    function NavbarController($state, Auth, Principal, ProfileService, LoginService, $uibModal,
        NotificationSocket, User, $rootScope, $interval, $scope, Navbar) {

        var vm = this;

        vm.clock;
        vm.dateRefresh;

        function tick() {
            vm.clock = moment(vm.clock).add(1, 'seconds').utc(true).format("YYYY-MM-DDTHH:mm:ss");
        }

        getDate();
        function getDate() {
            Navbar.getServerDate().$promise.then(function(data){
                vm.clock = data.dateTime;
                tick();
                vm.dateRefresh = $interval(tick, 1000);
            });
        }

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.settingsAccount = null;

        Principal.identity().then(function(account) {
            vm.settingsAccount = account;
        });

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerDisabled = response.swaggerDisabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.showAdminMenu = false;
        vm.collapseAdminMenu = collapseAdminMenu;

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            $rootScope.loggedOutByUser = true;
            User.logoutCurrent({}, function(result) {
                collapseNavbar();
                Auth.logout();
                NotificationSocket.disconnect();
                $state.go('login');
            });
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function collapseAdminMenu() {
            vm.showAdminMenu = !vm.showAdminMenu;
        }

        vm.showHelp = function() {
            $uibModal.open({
                templateUrl: 'app/entities/help/help.html',
                controller: 'HelpController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
        };
        vm.profile = function() {
            $state.go('profile');
        };
        vm.password = function() {
            $uibModal.open({
                templateUrl: 'app/password/password.html',
                controller: 'PasswordController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('password');
                        return $translate.refresh();
                    }]
                }
            });
        };
        vm.phonePassword = function() {
            $uibModal.open({
                templateUrl: 'app/password/phone-password.html',
                controller: 'PhonePasswordController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('password');
                        return $translate.refresh();
                    }]
                }
            });
        };

        $scope.$on('$destroy', function(){
            $interval.cancel(vm.dateRefresh);
        });

    }
})();
