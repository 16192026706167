(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .factory('PhaseUtils', PhaseUtils);

    function PhaseUtils () {

        var service = function(auction, bids) {
            this.phase = auction.phase;
            this.userPhase = getUserPhase(auction);
            this.isRunning = isRunning(auction);
            this.isSettled = isSettled(auction);
            this.isInvalidated = isInvalidated(auction);
            this.isInvalidatedOrInvalidatedDistributed = isInvalidatedOrInvalidatedDistributed(auction);
            this.isButtonAddBidsVisible = isRunning(auction);
            this.shouldShowAuctionResults = isSettled(auction);
            this.couldHaveBids = couldHaveBids(auction);
            this.isResolved = isResolved(auction);
        };
        return service;

        function getUserPhase(auction) {
            return auction.phase === "REQUIRES_CONFIRMATION" ? "FINISHED" : auction.phase;
        }

        function isRunning(auction) {
            return auction.phase === "RUNNING";
        }

        function isSettled(auction) {
            return auction.phase === "SETTLED";
        }

        function isInvalidated(auction) {
            return auction.phase === "INVALIDATED";
        }

        function isInvalidatedOrInvalidatedDistributed(auction) {
            return auction.phase === "INVALIDATED" || auction.phase === "INVALIDATED_DISTRIBUTED";
        }

        function couldHaveBids(auction) {
            return auction.phase === "RUNNING" || auction.phase === "FINISHED" || auction.phase === "REQUIRES_CONFIRMATION";
        }

        function isResolved(auction) {
            return auction.phase === "INVALIDATED" || auction.phase === "INVALIDATED_DISTRIBUTED" || auction.phase === "SETTLED";
        }
    }
})();
