(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('profile', {
                parent: 'entity',
                url: '/profile',
                data: {
                    pageTitle: 'Profile'
                },
                ncyBreadcrumb: {
                    label: 'gspUserApp.profile.title'
                },
                views: {
                    'content@logged': {
                        templateUrl: 'app/account/profile/profile.html',
                        controller: 'ProfileController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user');
                        $translatePartialLoader.addPart('member');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
