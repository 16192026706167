(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('AuctionReportsController', AuctionReportsController);

    AuctionReportsController.$inject = ['$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity',
        'AlertService', 'Report', '$translate'];

    function AuctionReportsController($scope, $rootScope, $stateParams, $uibModalInstance, entity,
        AlertService, Report, $translate) {
        var vm = this;

        vm.auction = entity;
        vm.clear = clear;

        vm.type = "ORDER_REPORT";
        vm.format = "CSV";

        var unsubscribe = $rootScope.$on('gspUserApp:auctionUpdate', function(event, result) {
            vm.auction = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.download = function() {
            Report.getForAuction({
                "auctionId": vm.auction.id,
                "type": vm.type,
                "format": vm.format,
                "langKey": $translate.storage().get('NG_TRANSLATE_LANG_KEY')
            }, function(data, headers) {
                var BOM = "\uFEFF";
                var blob = new Blob([ BOM + data.data ], { type : 'text/csv' });
                saveAs(blob, data.fileName);
                $uibModalInstance.close('download');
            }, function(error) {
                AlertService.error(error.data.message);
            });
        };
    }
})();
