(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('BidDialogController', BidDialogController);

    BidDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Bid', 'Auction', 'Client',
        '$http', '$window', 'Principal', 'injectCollateral', 'AlertService', 'Insurance', 'injectAuctionId'
    ];

    function BidDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Bid, Auction, Client,
        $http, $window, Principal, injectCollateral, AlertService, Insurance, injectAuctionId) {

        var vm = this;
        var lockSave = false;

        vm.bid = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.isDM = false;
        vm.inBehalfOwn = false;
        vm.selectedClient = {};
        vm.selectedClientObject = {};
        vm.collateral = {};

        vm.selectizeConfig = {
            create: false,
            valueField: 'id',
            labelField: 'code',
            allowEmptyOption: true
        };

        vm.initPrice = function() {
            if(!vm.bid.price) {
                vm.bid.price = vm.bid.auction.startingPricePerMonth;
            }
            if(!vm.bid.allowances) {
                vm.bid.allowances = 1;
            }
            vm.getAmount();
        };
        $timeout(function () {
            vm.initPrice();
        }, 500);

        vm.changeClientModel = function() {
            if (vm.selectedClient) {
                for (var i = 0; i < vm.clients.length; i++) {
                    if (vm.clients[i].id == vm.selectedClient) {
                        vm.selectedClientObject = vm.clients[i];
                        vm.updateCollateral();
                        return false;
                    }
                }
            }
        };

        vm.getAmount = function(){
            if(vm.bid.auction.agreementType === "MONTHLY") {
                vm.amount = (vm.bid.price * vm.bid.auction.serviceDuration * vm.bid.allowances) || 0;
            }
            if(vm.bid.auction.agreementType === "ANNUAL") {
                vm.amount = (vm.bid.price * vm.bid.auction.serviceDuration * vm.bid.allowances * 12) || 0;
            }
            vm.priceError = !Number.isInteger(parseFloat(vm.bid.price) - vm.bid.auction.startingPricePerMonth);
        };

        vm.priceMinimum = 0.01;

        vm.allowancesLowerThanMinimum = function () {
            vm.bid.auction.minAllowances = vm.bid.auction.minAllowances | 1;
            return vm.bid.allowances != undefined && vm.bid.auction.minAllowances != undefined && vm.bid.allowances < vm.bid.auction.minAllowances;
        };

        vm.allowancesHigherThanMaximum = function () {
            return vm.bid.allowances > vm.bid.auction.allowances;
        };

        vm.getRemainingAllowances = function() {
            return vm.collateral.remainingAllowances;
        };

        vm.bidValueGreaterThanRemainingInsuranceValue = function () {
            return vm.bid.allowances != undefined &&
                vm.bid.price != undefined &&
                vm.collateral.remainingInsuranceValue != undefined &&
                vm.bid.price * vm.bid.allowances > vm.collateral.remainingInsuranceValue;
        };

        vm.noRemainingBids = function () {
            return vm.collateral.remainingBids != null && vm.collateral.remainingBids <= 0;
        };

        vm.incorrectMember = function () {
            var correctMember = (vm.selectedMemberObject.id > 0);
            var correctClient = vm.isDM ? (vm.selectedClientObject.id > 0) : true;
            var correctUser = (vm.selectedUserObject.id > 0);
            return !correctMember || !correctClient || !correctUser;
        };

        vm.incorrectPrice = function () {
            return (vm.bid.price < vm.bid.auction.startingPricePerMonth) || vm.bid.price == 0;
        };

        vm.validationFailed = function () {
            return vm.incorrectPrice() ||
                vm.allowancesLowerThanMinimum() ||
                vm.allowancesHigherThanMaximum() ||
                vm.bidValueGreaterThanRemainingInsuranceValue() ||
                vm.noRemainingBids();
        };

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        }, 500);

        Principal.identity().then(function(account) {
            vm.account = account;
            vm.memberCode = vm.account.memberCode;;
            vm.memberId = vm.account.memberId;
            vm.groupId = vm.account.groupId;
            vm.isDM = vm.account.activityOnBehalf === 'CLIENT' || vm.account.activityOnBehalf === 'BOTH';
        });

        $timeout(function() {
            vm.updateCollateral();

        }, 1000);

        function clear() {
            if (vm.bid.id > 0) {
                vm.unlockEditedBid();
            }
            $uibModalInstance.dismiss('cancel');
        }

        function getClientGroupId() {
            try {
                return vm.selectedClientObject.group.id;
            } catch (err) {
                return null;
            }
        }

        function save() {
            if(lockSave === false){
                lockSave = true;
                vm.isSaving = true;
                vm.bid.lastUpdatedTime = null;
                vm.bid.memberCode = vm.memberCode;
                vm.bid.memberId = vm.memberId;
                vm.bid.groupId = vm.groupId;
                if (vm.isDM && !vm.inBehalfOwn) {
                    vm.bid.clientId = vm.selectedClientObject.id;
                    vm.bid.clientCode = vm.selectedClientObject.code;
                    vm.bid.groupId = getClientGroupId();
                } else {
                    vm.bid.clientId = null;
                    vm.bid.clientCode = null;
                }
                if (vm.bid.id !== null) {
                    Bid.update(vm.bid, onSaveSuccess, onSaveError);
                } else {
                    Bid.save(vm.bid, onSaveSuccess, onSaveError);
                }
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('gspUserApp:bidUpdate', result);
            $uibModalInstance.close(result);
            lockSave = false;
            if (vm.bid.id !== null) {
                AlertService.success("gspUserApp.bid.alert.updateSuccess", { id: result.id });
            } else {
                AlertService.success("gspUserApp.bid.alert.saveSuccess", { id: result.id });
            }
        }

        function onSaveError() {
            vm.isSaving = false;
            lockSave = false;
        }

        vm.datePickerOpenStatus.lastUpdatedTime = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.getAllowanceMinimum = function() {
            var minAllowances = 0;
            if (vm.bid.auction) {
                minAllowances = parseInt(vm.bid.auction.minAllowances);
            }
            return isNaN(minAllowances) ? 0 : minAllowances;
        };

        vm.lockEditedBid = function() {
            vm.collateral.remainingInsuranceValue += vm.bid.price * vm.bid.allowances;
            vm.collateral.remainingAllowances += vm.bid.allowances;
            vm.collateral.remainingBids++;
            Bid.lock({ 'id': vm.bid.id }, null, function(result) {});
        };

        vm.unlockEditedBid = function(operation) {
            vm.collateral.remainingInsuranceValue -= vm.bid.price * vm.bid.allowances;
            vm.collateral.remainingAllowances -= vm.bid.allowances;
            vm.collateral.remainingBids--;
        };

        $window.onbeforeunload = function() {
            vm.collateral.remainingInsuranceValue -= vm.bid.price * vm.bid.allowances;
            vm.collateral.remainingAllowances -= vm.bid.allowances;
            vm.collateral.remainingBids--;
            if(vm.bid.id > 0) {
                Bid.unlock({ 'id': vm.bid.id }, null, function(result) {});
            }
        };

        vm.clearSelectedClient = function() {
            vm.selectedClient = {};
            vm.selectedClientObject = {};
            vm.updateCollateral();
        };

        vm.restrictionsExcluded = function() {
            return vm.collateral.allowanceLimit && vm.bid.auction.minAllowances && vm.bid.auction.minAllowances > vm.collateral.allowanceLimit;
        };

        vm.updateCollateral = function() {
            var request = {
                memberId: vm.memberId,
                groupId: vm.groupId,
                auctionId: injectAuctionId
            };
            if (vm.isDM && !vm.inBehalfOwn && vm.selectedClientObject.id > 0) {
                request.clientId = vm.selectedClientObject.id;
                request.groupId = getClientGroupId();
                vm.collateral = Insurance.getForClientAndAuction(request);
            }
        };

        vm.incrementPrice = function () {
            vm.bid.price++;
        };

        $scope.$on("REFRESH_BIDS", function(event, args) {
            if (args.auction.id == injectAuctionId) {
                if (args.bid.memberId == vm.account.memberId ||
                    (args.bid.groupId && args.bid.groupId == vm.account.groupId)) {
                    vm.updateCollateral();
                }
            }
        });

        $scope.$on("MANUAL_BID_UNLOCK", function (event, args) {
            if (args.closeDialog) {
                $uibModalInstance.dismiss('cancel');
                AlertService.error("gspUserApp.bid.alert.bidUnlocked", {
                    id: args.bid.id
                });
            }
        });

    }
})();
