(function() {
    'use strict';

    var jhiItemCount = {
        template: '<div class="info" ng-show="$ctrl.queryCount!=undefined">' +
                    '{{"global.itemCount.showing" | translate}} {{(($ctrl.page-1) * $ctrl.itemsPerPage)==0 ? 1:(($ctrl.page-1) * $ctrl.itemsPerPage + 1)}} - ' +
                    '{{($ctrl.page * $ctrl.itemsPerPage) < $ctrl.queryCount ? ($ctrl.page * $ctrl.itemsPerPage) : $ctrl.queryCount}} ' +
                    '{{"global.itemCount.of" | translate}} {{$ctrl.queryCount}} {{"global.itemCount.items" | translate}}' +
                '</div>',
        bindings: {
            page: '<',
            queryCount: '<total',
            itemsPerPage: '<'
        }
    };

    angular
        .module('gspUserApp')
        .component('jhiItemCount', jhiItemCount);
})();
