(function () {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('DocumentationDialogController', DocumentationDialogController);

    DocumentationDialogController.$inject = ['$scope', '$uibModalInstance', 'entity', 'fromSubmission', 'Submission', 'Documentation', 'Dictionary', 'AlertService', 'Principal'];

    function DocumentationDialogController($scope, $uibModalInstance, entity, fromSubmission, Submission, Documentation, Dictionary, AlertService, Principal) {
        var vm = this;
        vm.document = entity;
        vm.fromSubmission = fromSubmission;
        vm.clear = clear;
        vm.save = save;
        vm.getProcessType = getProcessType;
        vm.getDocumentNames = getDocumentNames;
        vm.getDocumentTypes = getDocumentTypes;
        vm.removeFile = removeFile;
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {};
        vm.file = [];
        var formdata = new FormData();

        vm.selectizeConfig = {
            create: false,
            valueField: 'id',
            labelField: 'name',
            allowEmptyOption: false
        };

        Principal.identity().then(function(account) {
            vm.document.memberCode = account.memberCode;
            vm.document.memberId = account.memberId;
        });

        if (vm.fromSubmission) {
            vm.processCategories = [{ name: 'gspUserApp.documentation.processCategories.STORAGE_SERVICE_AGREEMENT', id: 'STORAGE_SERVICE_AGREEMENT' }];
            vm.processTypes = [{ name: 'gspUserApp.documentation.processTypes.SSA_CONCLUSION', id: 'SSA_CONCLUSION' }];
            vm.document.processCategory = 'STORAGE_SERVICE_AGREEMENT';
            vm.document.processType = 'SSA_CONCLUSION';
            getDocumentNames('STORAGE_SERVICE_AGREEMENT', 'SSA_CONCLUSION');
        } else {
            Dictionary.getProcessCategories().$promise.then(function (resp) {
                //TODO przenieść ograniczenia słowników na backend
                vm.processCategories = _.filter(resp, function (o) {
                    return o.id !== 'PUBLIC_INFORMATION' && o.id !== 'REMIT_AGREEMENT';
                });
            });
        }

        function getProcessType(processCategory) {
            Dictionary.getProcessTypesByProcessCategory({ processCategory: processCategory }).$promise.then(function (resp) {
                vm.processTypes = resp;
            });
        }

        function getDocumentNames(processCategory, processType) {
            Dictionary.getDocumentNamesByProcessCategoryAndProcessTypes({ processCategory: processCategory, processType: processType }).$promise.then(function (resp) {
                vm.documentNames = _.filter(resp, function (o) {
                    return o.id !== 'OSM_MESSAGE' && o.id !== 'REQUEST_TO_SUPPLEMENT_THE_APPLICATION';
                });
            });
        }

        function getDocumentTypes(documentName) {
            Dictionary.getDocumentTypesByDocumentName({ documentName: documentName }).$promise.then(function (resp) {
                vm.documentTypes = resp;
            });
        }

        function removeFile() {
            vm.file = [];
        }

        vm.getTheFiles = function($files) {
            vm.file = [];

            angular.forEach($files, function(value, key) {
                vm.file.push(value.name);
                formdata.append(key, value,encodeURI(value.name));
            });
        };

        function save() {
            formdata.append("documentType", vm.document.documentType);
            formdata.append("documentName", vm.document.documentName);
            formdata.append("processType", vm.document.processType);
            formdata.append("processCategory", vm.document.processCategory);
            formdata.append("memberId", vm.document.memberId);

            if (vm.fromSubmission) {
                formdata.append("submissionId", vm.document.submissionId);
                formdata.append("memberCode", vm.document.memberCode);
            } else {
                // var member = _.find(vm.members, function(o) { return o.id === Number(vm.document.memberId); });
                // formdata.append("memberCode", member.code);
                formdata.append("memberCode", vm.document.memberCode);
            }

            Documentation.saveDocumentation(formdata, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('gspAdminApp:documentationSave', result);
            AlertService.success("gspUserApp.documentation.alert.saveSuccess", {
                id: result.id
            });
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

    }
})();
