(function () {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('AllocationDecisionController', AllocationDecisionController);

    AllocationDecisionController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 'entity', 'submission', 'Submission', 'AlertService'];

    function AllocationDecisionController($scope, $uibModalInstance, $uibModal, entity, submission, Submission, AlertService) {
        var vm = this;
        vm.clear = clear;
        vm.sendAllocationDecision = sendAllocationDecision;

        vm.decision = entity;
        vm.submissionCopy = angular.copy(submission);

        if (vm.submissionCopy.allocationDecision) vm.decision = vm.submissionCopy.allocationDecision;

        vm.selectizeConfig = {
            create: false,
            valueField: 'value',
            labelField: 'name',
            allowEmptyOption: false
        };

        vm.decisionDict = [
            {
                value: 'ACCEPTED',
                name: 'gspUserApp.offer.decisionStatuses.ACCEPTED'
            },
            {
                value: 'REJECTED',
                name: 'gspUserApp.offer.decisionStatuses.REJECTED'
            },
            {
                value: 'WAITING',
                name: 'gspUserApp.offer.decisionStatuses.WAITING'
            }
        ];

        function sendAllocationDecision() {
            var params = {
                'submissionId': vm.submissionCopy.id
            };
            Submission.takeAllocationDecision(params, vm.decision, function(){
                AlertService.success("gspUserApp.offer.alert.saveDecisionSuccess", { id: vm.submissionCopy.id });
                $uibModalInstance.close(vm.decision);
            },function(error){
                onError(error);
            });
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
