(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$rootScope', '$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', '$interval', 'AuctionSettlement',
        'AlertService', '$http'
    ];

    function SidebarController() {

    }
})();
