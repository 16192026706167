(function() {
    'use strict';
    angular
        .module('gspUserApp')
        .factory('NotificationSocket', NotificationSocket);

    NotificationSocket.$inject = ['$state', '$rootScope', 'AuthServerProvider', 'Account', 'Principal', '$cookies', '$http'];

    function NotificationSocket($state, $rootScope, AuthServerProvider, Account, Principal, $cookies, $http) {
        var token = AuthServerProvider.getToken();
        var stompClient = null;

        var service = {
            getConnection: getConnection,
            disconnect: disconnect
        };

        function checkAccount() {
            if ($rootScope.loggedOutByUser !== true) {
                Account.checkToken().$promise.then(function(_resp) {
                    if (_resp.data != 'true') {
                        AuthServerProvider.logout();
                        $state.go('login', { 'redirectReason': 'sessionExpiredByAdmin' });
                    }
                });
            } else {
                delete $rootScope.loggedOutByUser;
            }
        }

        function getConnection() {
            var socket = new SockJS('/websocket');
            stompClient = Stomp.over(socket);
            stompClient.debug = false;
            stompClient.connect({}, function(frame) {
                stompClient.subscribe('/topic/events', function(event) {
                    setTimeout(function() {
                        handleEvent(event.body);
                    }, 800);
                });
            });
        }

        function disconnect() {
            if (stompClient != null) {
                stompClient.disconnect();
            }
        }

        function handleEvent(event) {
            if (event === 'REFRESH_USER_TOKEN') {
                checkAccount();
            } else if (event === 'NEW_AUCTION_SETTLEMENT') {
                $rootScope.$broadcast('NEW_AUCTION_SETTLEMENT');
            } else if (event === 'NEW_NOTIFICATION') {
                $rootScope.$broadcast('NEW_NOTIFICATION');
            } else {
                try {
                    var eventObject = angular.fromJson(event);
                    if (typeof eventObject === 'object') {
                        if ('AUCTION_BID_MODIFIED' === eventObject.event) {
                            $rootScope.$broadcast('REFRESH_BIDS', { auction: eventObject.auction, bid: eventObject.bid });
                        } else if ('AUCTION_MODIFIED' === eventObject.event) {
                            $rootScope.$broadcast('REFRESH_AUCTION', { auction: eventObject.auction });
                        } else if ('NEW_NOTIFICATION' === eventObject.event) {
                            $rootScope.$broadcast('NEW_NOTIFICATION', { notification: eventObject.notification });
                        } else if ('NEW_AUCTION_SETTLEMENT' === eventObject.event) {
                            $rootScope.$broadcast('NEW_AUCTION_SETTLEMENT', { exchangeRate: eventObject.message });
                        } else if ('MANUAL_BID_UNLOCK' === eventObject.event) {
                            $rootScope.$broadcast('MANUAL_BID_UNLOCK', { closeDialog: true, bid: eventObject.bid });
                        } else if ('OFFER_MODIFIED' === eventObject.event) {
                            $rootScope.$broadcast('REFRESH_OFFER', { offer: eventObject.offer });
                        } else if ('SUBMISSION_MODIFIED' === eventObject.event) {
                            $rootScope.$broadcast('REFRESH_SUBMISSION', { offer: eventObject.offer, submission: eventObject.submission });
                        }
                    }
                } catch (err) {}
            }
        }

        return service;
    }

})();
