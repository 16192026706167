(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .factory('Account', Account);

    Account.$inject = ['$resource', 'AuthServerProvider', 'jwtHelper', '$rootScope'];

    function Account($resource, AuthServerProvider, jwtHelper, $rootScope) {
        var service = {
            get: get,
            passwordExpired: passwordExpired,
            forceChangePhonePassword: forceChangePhonePassword,
            checkToken: checkToken
        };
        var isPasswordExpired = false;
        var isForceChangePhonePassword = false;

        return service;

        function passwordExpired() {
            return isPasswordExpired;
        }

        function checkToken() {
            var token = AuthServerProvider.getToken();
            var checkToken = $resource('gspidentity/api/check-token', {}, {
                'get': {
                    method: 'GET',
                    params: {
                        'token': token,
                        'ip': jwtHelper.decodeToken(token).ipAddress
                    },
                    transformResponse: function(_response) {
                        var data = {
                            'data': _response
                        };
                        return data;
                    }
                }
            });

            return checkToken.get();
        }

        function forceChangePhonePassword() {
            return isForceChangePhonePassword;
        }

        function get() {
            var account = $resource('gspidentity/api/account', {}, {
                'get': {
                    method: 'GET',
                    params: {},
                    isArray: false,
                    interceptor: {
                        response: function(response) {
                            if (response.data.passwordExpired === true || response.data.forceChangePassword === true) {
                                isPasswordExpired = true;
                            } else {
                                isPasswordExpired = false;
                            }
                            if (response.data.forceChangePhonePassword === true) {
                                isForceChangePhonePassword = true;
                            } else {
                                isForceChangePhonePassword = false;
                            }
                            return response;
                        }
                    }
                }
            });

            return account.get();
        }
    }
})();
