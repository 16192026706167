(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('SubmissionsController', SubmissionsController);

    SubmissionsController.$inject = ['$scope', '$rootScope', '$state', 'AlertService', 'Submission', 'DateUtils', 'ParseLinks', 'pagingParams'];

    function SubmissionsController($scope, $rootScope, $state, AlertService, Submission, DateUtils, ParseLinks, pagingParams) {
        var vm = this;

        vm.availableItemsPerPage = [{
            value: 20,
            label: "20"
        }, {
            value: 50,
            label: "50"
        }, {
            value: 100,
            label: "100"
        }];
        vm.itemsPerPage = vm.availableItemsPerPage[pagingParams.itemsPerPage];

        vm.updateData = updateData;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        var queryParams = {
            page: vm.page,
            size: vm.itemsPerPage.value
        };

        vm.submissions = [];
        vm.emptyTable = false;

        vm.isFiltered = false;
        vm.queryParamsExt = {
            "dateFrom": null,
            "dateTo": null,
            page: vm.page,
            size: vm.itemsPerPage.value,
            sort: null
        };

        loadAll();

        function loadAll() {
            var queryParams = {
                page: pagingParams.page - 1,
                size: vm.itemsPerPage.value,
                sort: sort()
            };

            var copyQueryParamsExt = [];

            if (vm.isFiltered) {
                copyQueryParamsExt = angular.copy(vm.queryParamsExt);
                copyQueryParamsExt.dateFrom = DateUtils.removeTimeZone(copyQueryParamsExt.dateFrom);
                copyQueryParamsExt.dateTo = DateUtils.removeTimeZone(copyQueryParamsExt.dateTo);
                copyQueryParamsExt.sort = sort();
            }


            Submission.getSubmissionsByMemberOfLoggedInUser(vm.isFiltered ? copyQueryParamsExt : queryParams, onSuccess, onError);


            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.submissions = data;
                vm.page = pagingParams.page;

                vm.emptyTable = vm.submissions.length === 0;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                itemsPerPage: vm.findIndexItemsPerPage()
            });
        }

        vm.findIndexItemsPerPage = function () {
            for (var i = 0; i < vm.availableItemsPerPage.length; i++) {
                if (vm.availableItemsPerPage[i].value === vm.itemsPerPage.value) {
                    return i;
                }
            }
            return 0;
        };

        vm.changeItemsPerPage = function (item) {
            vm.itemsPerPage = item;
            vm.loadPage(1);
            loadAll();
        };


        function updateData() {
            loadAll();
        }

        vm.datePickerOpenStatus = {
            fromDate: false,
            toDate: false
        };

        vm.isFromDateBeforeToDate = function() {
            if (vm.queryParamsExt.dateFrom && vm.queryParamsExt.dateTo) {
                return vm.queryParamsExt && vm.queryParamsExt.dateFrom === undefined
                    || vm.queryParamsExt.dateTo === undefined || moment(vm.queryParamsExt.dateFrom).isBefore(vm.queryParamsExt.dateTo);
            } else {
                return true;
            }
        };

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        vm.filter = function() {
            vm.isFiltered = true;
            vm.queryParamsExt.page = 0;
            vm.submissions = [];
            loadAll();
        };

        vm.clearFilter = function() {
            queryParams.page = 0;
            vm.isFiltered = false;

            vm.queryParamsExt.dateFrom = null;
            vm.queryParamsExt.dateTo = null;
            $scope.editForm.$setPristine();
            vm.queryParamsExt.page = 0;

            vm.submissions = [];
            loadAll();
        };

    }
})();
