(function () {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('OfferDetailController', OfferDetailController);

    OfferDetailController.$inject = ['$scope', '$rootScope', 'entity', 'ParseLinks', 'AlertService', 'PaginationUtil', 'Submission', 'paginationConstants', '$uibModal', '$stateParams',
        '$state', '$http', 'Principal', 'Offer', 'Auction', '$filter', '$confirm',
        'Report', '$translate', 'User', '$cookies', 'cookiePagingParams'
    ];

    function OfferDetailController($scope, $rootScope, entity, ParseLinks, AlertService, PaginationUtil, Submission, paginationConstants, $uibModal, $stateParams, $state, $http, Principal, Offer, Auction, $filter, $confirm,
        Report, ngTranslate, User, $cookies, cookiePagingParams) {
        var vm = this;
        vm.offer = entity;
        vm.buttonVisibility = buttonVisibility;
        vm.getClusterRowVisibility = getClusterRowVisibility;
        vm.getSubClustersRowVisibility = getSubClustersRowVisibility;
        vm.openSubmissionDetail = openSubmissionDetail;
        var $translate = $filter('translate');

        vm.toggle = {
            offerParameters: '',
            offerProfile: '',
            parametersOfServiceOffered: '',
            clusterParameters: '',
            clusterRows: [],
            submissionParentRows: [],
            submissionClusterRootRows: [],
            submissionClusterChildRows: []
        };
        if (cookiePagingParams !== null) {
            vm.toggle = cookiePagingParams;
        }

        function buttonVisibility(type) {
            switch (type) {
            case 'edit':
                return (vm.offer.phase === "NEW" || vm.offer.phase === "PUBLISHED" || vm.offer.phase === "RUNNING" );
            case 'delete':
                return (vm.offer.phase === "NEW");
            case 'publish':
                return (vm.offer.phase === "NEW");
            case 'start':
                return (vm.offer.phase === "PUBLISHED");
            case 'reject':
                return (vm.offer.phase === "PUBLISHED" || vm.offer.phase === "FINISHED");
            case 'finish':
                return (vm.offer.phase === "RUNNING");
            case 'addSubmission':
                return (vm.offer.phase === 'RUNNING');
            }
        }

        function getClusterRowVisibility(clusterId) {
            if (clusterId) {
                var submission = _.find(vm.submissions, { clusterId: clusterId });
                return !!(submission);
            }
        }

        function getSubClustersRowVisibility(cluster) {
            if (cluster.subClusters.length > 0) {
                return _.forEach(cluster.subClusters, function (subCluster) {
                    return getClusterRowVisibility(subCluster.id);
                });
            }
        }

        vm.updateToggle = function () {
            $cookies.putObject('offerToggle', vm.toggle);
        };

        var unsubscribe = $rootScope.$on('gspUserApp:offerUpdate', function (event, result) {
            vm.offer = result;
            vm.offer.warehouseInstallation.id ? findWarehouse(vm.offer.warehouseInstallation.id) : findWarehouse(vm.offer.warehouseInstallationId);
        });
        $scope.$on('$destroy', unsubscribe);

        vm.page = 1;
        vm.sort = 'id,desc';
        vm.predicate = PaginationUtil.parsePredicate(vm.sort);
        vm.reverse = PaginationUtil.parseAscending(vm.sort);

        var findWarehouse = function(_id){
            Auction.getWarehouseInstallations().$promise.then(function(resp){
                var warehouse = _.find(resp, {id: parseInt(_id)});
                vm.offer.warehouseInstallation = warehouse.name || '';
                vm.offer.eicCode = warehouse.codeEIC;
            });
        };

        function getOfferSubmissions() {
            Submission.getSubmissionsByOfferId({
                offerId: vm.offer.id
            }).$promise.then(function (resp) {
                vm.submissions = resp;
            });
        }

        vm.$onInit = function() {
            findWarehouse(vm.offer.warehouseInstallationId);
            getOfferSubmissions();
        };

        vm.openSubmissionModal = function () {
            $uibModal.open({
                templateUrl: 'app/entities/submission/submission-dialog.html',
                controller: 'SubmissionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function () {
                        return {
                            id: null,
                            memberCode: null,
                            memberId: null,
                            username: null,
                            status: 'SAVED',
                            agreementType: null,
                            offerId: vm.offer.id,
                            reductionPermit: false
                        };
                    }],
                    offer: [function () {
                        return vm.offer;
                    }],
                    baseCluster: ['Offer', function (Offer) {
                        return Offer.getInitialBaseClusterForSubmission({
                            id: vm.offer.id
                        }).$promise;
                    }]
                }
            }).result.then(function () {
                getOfferSubmissions();
            }, function () {
                getOfferSubmissions();
            });
        };


        function openSubmissionDetail(id, clusterRoot) {
            $uibModal.open({
                templateUrl: 'app/entities/submission/submission-detail/submission-detail.html',
                controller: 'SubmissionDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['$stateParams', 'Submission', function($stateParams, Submission) {
                        return Submission.getSubmissionById({
                            id: id
                        }).$promise;
                    }],
                    offer: ['$stateParams', 'Offer', function($stateParams, Offer) {
                        return Offer.get({
                            id: vm.offer.id
                        }).$promise;
                    }],
                    clusterRoot: clusterRoot,
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('documentation');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function () {
                Offer.get({id: vm.offer.id}).$promise.then(function (resp) {
                    vm.offer = resp;
                    getOfferSubmissions();
                    findWarehouse(vm.offer.warehouseInstallationId);
                });
            }, function () {
            });
        }

        var refreshOfferWatcher = $scope.$on("REFRESH_OFFER", function (event, args) {
            if (args.offer.id && args.offer.id === vm.offer.id) {
                Offer.get({id: vm.offer.id}).$promise.then(function (resp) {
                    vm.offer = resp;
                    getOfferSubmissions();
                    findWarehouse(vm.offer.warehouseInstallationId);
                });
            }
        });
        $scope.$on('$destroy', refreshOfferWatcher);

        var refreshSubmissionWatcher = $scope.$on("REFRESH_SUBMISSION", function (event, args) {
            if (args.submission.id) {
                Offer.get({id: vm.offer.id}).$promise.then(function (resp) {
                    vm.offer = resp;
                    getOfferSubmissions();
                    findWarehouse(vm.offer.warehouseInstallationId);
                });
            }
        });
        $scope.$on('$destroy', refreshSubmissionWatcher);

    }
})();
