(function () {
    'use strict';

    angular
        .module('gspUserApp')
        .factory('Documentation', Documentation);

    Documentation.$inject = ['$resource'];

    function Documentation ($resource) {
        var resourceUrl =  'gspserver/' + 'api/documentation/';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    return data;
                }
            },
            'getDocumentationsBySubmissionId': {
                method: 'GET',
                url: 'gspserver/api/documentation/getDocumentationsBySubmissionId/:submissionId',
                isArray: true
            },
            'getDocumentationFile': {
                method: 'GET',
                url: 'gspserver/api/documentation/getDocumentationFile/:id',
                responseType: 'arraybuffer'
            },
            'saveDocumentation': {
                method: 'POST',
                url: 'gspserver/api/documentation/saveDocumentation',
                headers: {
                    'Content-Type': undefined
                }
            },
            'operation': {
                method: 'POST',
                url: resourceUrl + 'documentation-operation'
            },
            'deleteDocumentationFile': {
                method: "PUT",
                url: resourceUrl + 'deleteDocumentationFile/:id'
            }
        });
    }
})();
