(function () {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('OfferController', OfferController);

    OfferController.$inject = ['$scope', '$rootScope', '$state', '$timeout', 'Offer', 'Dictionary', 'ParseLinks', 'AlertService', 'pagingParams',
        'paginationConstants', '$cookies', 'cookiePagingParams'];

    function OfferController($scope, $rootScope, $state, $timeout, Offer, Dictionary, ParseLinks, AlertService, pagingParams,
                             paginationConstants, $cookies, cookiePagingParams) {

        var vm = this;

        vm.availableItemsPerPage = [{
            value: 20,
            label: "20"
        }, {
            value: 50,
            label: "50"
        }, {
            value: 100,
            label: "100"
        }];
        vm.itemsPerPage = vm.availableItemsPerPage[pagingParams.itemsPerPage];
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.updateData = updateData;
        vm.from = $state.params.from;
        vm.getWarehouseName = getWarehouseName;

        switch (vm.from) {
            case 'all':
                $state.current.ncyBreadcrumb.label = "gspUserApp.offer.home.titleAll";
                break;
            case 'running':
                $state.current.ncyBreadcrumb.label = "gspUserApp.offer.home.titleRunning";
                break;
        }

        Dictionary.getWarehouseInstallationDictionary().$promise.then(function (resp) {
            vm.warehouses = resp;
        });

        loadAll();

        function loadAll(_refresh) {
            var queryParams = {
                page: pagingParams.page - 1,
                size: vm.itemsPerPage.value,
                sort: sort(),
                from: vm.from
            };
            if (_refresh) {
                queryParams = cookiePagingParams;
                var splited = queryParams.sort[0].split(',');
                vm.predicate = splited[0];
                vm.reverse = splited[1];
            }
            $cookies.putObject('offerParams', queryParams);
            Offer.query(queryParams, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.offers = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function updateData() {
            loadAll();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                from: vm.from,
                search: vm.currentSearch,
                itemsPerPage: vm.findIndexItemsPerPage()
            });
        }

        vm.findIndexItemsPerPage = function () {
            for (var i = 0; i < vm.availableItemsPerPage.length; i++) {
                if (vm.availableItemsPerPage[i].value === vm.itemsPerPage.value) {
                    return i;
                }
            }
            return 0;
        };

        vm.changeItemsPerPage = function (item) {
            vm.itemsPerPage = item;
            vm.loadPage(1);
            loadAll();
        };

        function getWarehouseName(id) {
            var warehouse = _.find(vm.warehouses, function (item) {
                return item.id === id;
            });
            return warehouse.name;
        }

        function addNewOfertFromSocket(offerExist, args) {
            if (offerExist) {
                _.forEach(vm.offers, function (offer, index) {
                    if (offer.id === args.offer.id) {
                        vm.offers[index] = args.offer;
                    }
                });
            } else {
                vm.offers.unshift(args.offer);
            }
        }

        var refreshOfferWatcher = $scope.$on("REFRESH_OFFER", function (event, args) {
            var offerExist = {};
            if (vm.from === 'running') {
                if (args.offer.id && (args.offer.phase !== 'NEW' || args.offer.phase !== 'PUBLISHED')) {
                    offerExist = _.find(vm.offers, function(o) { return o.id === args.offer.id; });
                    addNewOfertFromSocket(offerExist, args);
                }
            } else {
                if (args.offer.id && args.offer.phase !== 'NEW') {
                    offerExist = _.find(vm.offers, function(o) { return o.id === args.offer.id; });
                    addNewOfertFromSocket(offerExist, args);
                }
            }
        });
        $scope.$on('$destroy', refreshOfferWatcher);

    }
})();
