(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .controller('PasswordResetController', PasswordResetController);

    PasswordResetController.$inject = ['$scope', '$uibModalInstance', '$translate', '$stateParams', 'PasswordResetFinish',
        '$timeout', '$state'];

    function PasswordResetController ($scope, $uibModalInstance, $translate, $stateParams, PasswordResetFinish,
        $timeout, $state) {

        var vm = this;

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;
        vm.clear = clear;
        var passwordRegex = /(?=.{10,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/

        vm.init = function () {
            $translate.use($stateParams.lang);
        };
        vm.init();

        function clear() {
            $uibModalInstance.dismiss('OK');
        }

        function changePassword () {
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                vm.doNotMatch = null;
                var requestDTO = {
                    'password' : vm.password,
                    'token': $stateParams.token
                }
                PasswordResetFinish.save(requestDTO, function () {
                    vm.error = null;
                    vm.success = 'OK';
                    vm.password = vm.confirmPassword = null;
                    $scope.form.$setPristine();
                    $timeout(function() {
                        $state.go('login');
                    }, 1000);
                }, function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }

        vm.checkPasswordStrength = function() {
            return vm.password && !passwordRegex.test(vm.password);
        };
    }
})();
