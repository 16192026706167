(function() {
    'use strict';

    angular
        .module('gspUserApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('documentation', {
                parent: 'entity',
                url: '/documentation?page&sort&search&itemsPerPage',
                data: {
                    authorities: ['CAN_SEE_DOCUMENTATION_LIBRARY'],
                    pageTitle: 'Documentation'
                },
                ncyBreadcrumb: {
                    label: 'gspUserApp.documentation.home.title'
                },
                views: {
                    'content@logged': {
                        templateUrl: 'app/entities/documentation/documentation.html',
                        controller: 'DocumentationController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    memberForNewUser: null,
                    memberForNewClient: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            itemsPerPage: ($stateParams.itemsPerPage ? $stateParams.itemsPerPage : 0)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('documentation');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('paginator');
                        return $translate.refresh();
                    }]
                }
            })
            .state('documentation.new', {
                parent: 'documentation',
                url: '/new',
                ncyBreadcrumb: {
                    label: 'Dodaj nowy dokument',
                    parent: 'documentation'
                },
                data: {
                    authorities: ['CAN_ADD_DOCUMENTATION']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/documentation/documentation-dialog/documentation-dialog.html',
                        controller: 'DocumentationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    documentStatus: 'SUBMITTED'
                                };
                            },
                            fromSubmission: false,
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('documentation');
                                $translatePartialLoader.addPart('auction');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('paginator');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('documentation', null, {
                            reload: true
                        });
                    }, function() {
                        $state.go('documentation');
                    });
                }]
            });
    }
})();
