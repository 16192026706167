   (function() {
       angular
           .module('gspUserApp')
           .directive('tableFixedHeader', tableFixedHeader);

       tableFixedHeader.$inject = ['$timeout', '$log', '$window', '$rootScope'];

       function tableFixedHeader($timeout, $log, $window, $rootScope) {
           var directive = {
               require: '?ngModel',
               scope: {
                   floatThead: '=?',
                   floatTheadEnabled: '=?'
               },
               controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
                   if (!$attrs.hasOwnProperty('floatTheadEnabled')) {
                       $scope.floatTheadEnabled = $attrs.floatTheadEnabled = true;
                   }
                   if (!$attrs.hasOwnProperty('floatThead') || $attrs.floatThead === '') {
                       $scope.floatThead = $attrs.floatThead = {};
                   }
               }],
               link: link,
               restrict: 'A'
           };
           return directive;

           function link($scope, $element, $attrs, ngModel) {
               var $table = jQuery($element[0]);
               var $container = $table.closest('.scroll-container');
               var $wrapper = $table.closest('.table-fixed-header-wrapper-footer');
               var isFloated = false;

               if (ngModel) {
                   ngModel.$formatters.push(function() {
                       if ($scope.floatTheadEnabled && !isFloated) {
                           turnOnFloatThead();
                       }
                       if ($scope.floatTheadEnabled && angular.isObject($scope.floatThead)) {
                           $timeout(function() {
                               updateFooter();
                           });
                       }
                   });
               }

               function turnOnFloatThead() {
                   $container.perfectScrollbar();
                   $table.floatThead({
                       position: 'fixed',
                       scrollContainer: true
                   });
                   updateFooter();
                   isFloated = true;
               }

               function updateFooter() {
                   $timeout(function() {
                       var tab = $('<table></table>').attr("class", $table.attr("class")).addClass('sticky-footer');
                       tab.append($table.find('colgroup').clone());
                       tab.append($table.find('tfoot').clone());

                       $wrapper.find('table.sticky-footer').remove();
                       $wrapper.append(tab);

                       //ie fix
                       var ua = window.navigator.userAgent;
                       var msie = ua.indexOf("MSIE ");
                       if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
                           var $floatTheadcontainer = jQuery($container.find(".floatThead-container")[0]);
                           $floatTheadcontainer.addClass('auto-width');
                       }
                       $table.floatThead('reflow');
                       $container.perfectScrollbar('update');
                   }, 10);
               }

               window.onresize = function() {
                   updateFooter();
               };

               window.onchange = function() {
                   updateFooter();
               };

               $rootScope.$on('$translateChangeSuccess', function() {
                   updateFooter();
               });

               $element.bind('update', function() {
                   updateFooter();
               });

               $element.bind('$destroy', function() {
                   $table.floatThead('destroy');
               });
           }
       }
   })();
